import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EnabledQueryOption } from '~/adapters/TanStackQuery/types';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { sendChangeAccountName, sendChangeContactEmail } from '~/adapters/services/profiles/profile/tracking';
import { useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { userAccountsKey } from '~/adapters/services/accounts/users/hooks';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useAccountsRepository } from './rest';

const personalAccountKey = 'personal-account';
const accountKey = 'account';
const accountUsersKey = (accountId: number) => [accountKey, accountId, 'users'] as const;

export const usePersonalAccount = (options: EnabledQueryOption = {}) => {
    const { enabled = true } = options;
    const authUser = useOptionalAuthUser();
    const { getPersonalAccount } = useAccountsRepository();

    const isEmailAvailable = authUser && Boolean(authUser?.email);

    return useQuery({
        queryKey: [personalAccountKey, authUser?.email],
        queryFn: async () => {
            if (!isEmailAvailable) {
                return null;
            }
            return getPersonalAccount(authUser.email);
        },
        staleTime: 10000,
        enabled,
    });
};

export const useGetAccount = (accountId?: number, options: EnabledQueryOption = {}) => {
    const { enabled = true } = options;
    const { getAccount } = useAccountsRepository();
    return useQuery({
        queryKey: [accountKey, accountId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getAccount(accountId!),
        staleTime: 10000,
        enabled: enabled && Boolean(accountId),
    });
};

export const useUpdateContactEmail = () => {
    const { updateAccount } = useAccountsRepository();

    const client = useQueryClient();
    const authUser = useOptionalAuthUser();

    return useMutation({
        mutationFn: updateAccount,
        onMutate: () => {
            sendChangeContactEmail('Start');
        },
        onSuccess: async (account) => {
            sendChangeContactEmail('Success');
            if (account.personal) {
                client.setQueryData([personalAccountKey, authUser?.email], account);
            } else {
                client.setQueryData([accountKey, account.id], account);
            }
            await client.invalidateQueries({ queryKey: [userAccountsKey] });
            pushSuccessNotification('Contact email was updated.');
        },
        onError: () => {
            sendChangeContactEmail('Error');
            pushApiErrorNotification('Your contact email could not be updated.');
        },
    });
};

export const useUpdateAccountName = () => {
    const { updateAccount } = useAccountsRepository();

    const client = useQueryClient();
    const authUser = useOptionalAuthUser();

    return useMutation({
        mutationFn: updateAccount,
        onMutate: () => {
            sendChangeAccountName('Start');
        },
        onSuccess: async (account) => {
            sendChangeAccountName('Success');
            if (account.personal) {
                client.setQueryData([personalAccountKey, authUser?.email], account);
            } else {
                client.setQueryData([accountKey, account.id], account);
            }
            await client.invalidateQueries({ queryKey: [userAccountsKey] });
            pushSuccessNotification('Account name was updated.');
        },
        onError: () => {
            sendChangeAccountName('Error');
            pushApiErrorNotification('Your account name could not be updated.');
        },
    });
};

export const useCreateAccount = () => {
    const { createAccount } = useAccountsRepository();

    const client = useQueryClient();

    return useMutation({
        mutationFn: createAccount,
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: [userAccountsKey] });
            pushSuccessNotification('The new Team was created.');
        },
        onError: () => {
            pushApiErrorNotification('Your team could not be created.');
        },
    });
};

export const useGetAccountUsers = (accountId: number, options: EnabledQueryOption = {}) => {
    const { enabled = true } = options;
    const { getAccountUsers } = useAccountsRepository();
    return useQuery({
        queryKey: accountUsersKey(accountId),
        queryFn: () => getAccountUsers(accountId),
        staleTime: 10000,
        enabled,
    });
};

export const useInviteAccountUsers = () => {
    const { inviteAccountUsers } = useAccountsRepository();
    const client = useQueryClient();

    return useMutation({
        mutationFn: inviteAccountUsers,
        onSuccess: async (_data, variables) => {
            await client.invalidateQueries({ queryKey: accountUsersKey(variables.accountId) });
        },
    });
};

export const useRemoveAccountUser = () => {
    const { removeAccountUser } = useAccountsRepository();
    const client = useQueryClient();
    const { user } = useUserAccount();

    return useMutation({
        mutationFn: removeAccountUser,
        onSuccess: async (_data, variables) => {
            if (variables.userId === user.id) {
                await client.invalidateQueries({ queryKey: [userAccountsKey] });
                // No need to invalidate `accountUsersKey` because the account we just left won't be shown anymore
                pushSuccessNotification('You left the team.');
            } else {
                await client.invalidateQueries({ queryKey: accountUsersKey(variables.accountId) });
                pushSuccessNotification('The user was removed from the team.');
            }
        },
        onError: () => {
            pushApiErrorNotification('The user could not be removed from the team.');
        },
    });
};
