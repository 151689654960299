import Signaler from '@customink/signalerjs';

/*
 Rules:
 - Sum of all variants of `flags` should be 1
 - When you are changing rollout values of `flags`, you should change name of feature flag (for example add_to_cart_notification_v0 -> add_to_cart_notification_v1)
 - you can change value of feature flag in console, e.g. window.signaler.setFeatureFlag('add_to_cart_notification_v0', 'test')
 - docs https://github.com/customink/signalerjs/tree/master
 */
export const features = {
    designs_page_art_proofs_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
    add_to_cart_notification_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
    team_accounts_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
    flags_page: {
        flags: {
            test: 0,
            off: 1,
        },
    },
    add_to_cart_prominence_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
} as const;

export type SignalerFeatureFlagKey = keyof typeof features;

export const signaler = new Signaler(features);
window.signaler = signaler;
