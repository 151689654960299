import { useMemo } from 'react';
import { ApiCall } from '~/adapters/services/shared/apiCall';
import { User } from '~/adapters/services/accounts/users/types';
import {
    Account,
    PersonalAccount,
    transformAccountDtoToAccount,
    transformAccountDtoToPersonalAccount,
} from '~/contexts/UserAccount/account';
import { useAccountsApiCall } from '../accountsApiCall';
import {
    AccountDto,
    CreateAccountRequest,
    InviteUsersToAccountRequest,
    PersonalAccountDto,
    RemoveUserFromAccountRequest,
    UpdateAccountRequest,
} from './types';

interface GetPersonalQueryParams {
    email: string;
}

class AccountsRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getPersonalAccount = async (email: string): Promise<PersonalAccount> => {
        const params: GetPersonalQueryParams = { email };
        const response = await this.accountsApiCall<PersonalAccountDto>(`/accounts/personal`, { params });
        return transformAccountDtoToPersonalAccount(response.data);
    };

    public getAccount = async (accountId: number): Promise<Account> => {
        const response = await this.accountsApiCall<AccountDto>(`/accounts/${accountId}`);
        return transformAccountDtoToAccount(response.data);
    };

    public updateAccount = async ({ accountId, ...data }: UpdateAccountRequest) => {
        const response = await this.accountsApiCall<AccountDto>(`/accounts/${accountId}`, {
            method: 'PUT',
            data,
        });
        return transformAccountDtoToAccount(response.data);
    };

    public createAccount = async (data: CreateAccountRequest) => {
        const response = await this.accountsApiCall<AccountDto>(`/accounts`, {
            method: 'POST',
            data,
        });
        return transformAccountDtoToAccount(response.data);
    };

    public getAccountUsers = async (accountId: number) => {
        const response = await this.accountsApiCall<User[]>(`/accounts/${accountId}/users`);
        return response.data;
    };

    public inviteAccountUsers = async ({ accountId, emails }: InviteUsersToAccountRequest) => {
        return this.accountsApiCall<void>(`/accounts/${accountId}/invite`, {
            method: 'POST',
            data: { emails },
        });
    };

    public removeAccountUser = async ({ accountId, userId }: RemoveUserFromAccountRequest) => {
        return this.accountsApiCall<void>(`/accounts/${accountId}/users/${userId}`, {
            method: 'DELETE',
        });
    };
}

export const useAccountsRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new AccountsRepository(accountsApiCall), []);
};
