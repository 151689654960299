import { Typography } from '@mui/material';
import { Grid, Stack } from '@customink/pigment-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Loader } from '~/components/Loader/loader';
import { filterNullishFromArray } from '~/adapters/typescript/filterNullishFromArray';
import { useAuthenticatedCta } from '~/components/hooks/useAuthenticatedCta';
import getQueryVariable from '~/adapters/browser/getQueryVariable';
import { usePage } from '~/adapters/browser/usePage';
import {
    DesignReviewEditableEventType,
    DesignReviewEvent,
    DesignStatusEnum,
    GetDesignReviewQuery,
    SharingRole,
} from '~/adapters/services/collaboration/graphqlTypes';
import {
    InfiniteDesignReviewKey,
    useCreateEventMutation,
    useInfiniteGetDesignReviewQuery,
} from '~/adapters/services/collaboration/collaborationHooks';
import { CollaborationAbilityProvider } from '~/contexts/ability/collaboration/CollaborationAbilityContext';
import { sendFeedbackPageEvent } from './sendFeedbackPageEvent';
import { FeedbackAction } from './FeedbackAction';
import { FeedbackCantCommentInfo } from './FeedbackInfo';
import { FeedbackTimeline } from './FeedbackTimeline';
import { FeedbackComment } from './FeedbackComment';
import { FeedbackStatus } from './FeedbackStatus';
import { FeedbackPreview } from './FeedbackPreview';
import { useFeedbackParams } from './useFeedbackParams';

function toEvents(designReview: GetDesignReviewQuery): DesignReviewEvent[] | undefined {
    return designReview.resource?.events.edges?.map((edge) => edge.node);
}

const FeedbackHeaderRow = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

export function Feedback() {
    // resourceId is guaranteed, because the route doesn't match without a truthy id provided
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const resourceId = useFeedbackParams().resourceId!;

    const [comment, setComment] = useState('');
    const client = useQueryClient();
    usePage({ title: 'Feedback', reportPath: '/accounts/feedback' });

    const { mutate: sendEvent } = useCreateEventMutation({
        onSuccess: () => client.invalidateQueries({ queryKey: [InfiniteDesignReviewKey, resourceId] }),
    });

    const { data, isLoading, fetchNextPage, isFetching, hasNextPage } = useInfiniteGetDesignReviewQuery({
        input: { id: resourceId },
    });

    const clearComment = useCallback(() => {
        setComment('');
    }, [setComment]);

    const sendComment = useAuthenticatedCta(
        useCallback(() => {
            if (!comment.length) {
                return;
            }
            sendFeedbackPageEvent('Button Click', 'Comment');
            sendEvent({
                input: {
                    data: comment,
                    type: DesignReviewEditableEventType.Comment,
                    resourceId,
                },
            });
            clearComment();
        }, [sendEvent, comment, resourceId]),
    );

    const feedbackResource = useMemo(() => {
        if (data?.pages[0] && data?.pages[0].resource === null) {
            throw new Error(`Resource ${resourceId} is null.`);
        }
        return data?.pages[0].resource;
    }, [data, resourceId]);

    const feedbackPreviews: string[] = useMemo(() => {
        if (!feedbackResource) {
            return [];
        }
        return feedbackResource.design.products.map((product) => product.previews).flat();
    }, [feedbackResource]);

    const designStatus = useMemo(() => {
        return feedbackResource?.designStatus;
    }, [feedbackResource]);

    const events = useMemo(() => {
        return filterNullishFromArray(data?.pages.map(toEvents) ?? []).flat();
    }, [data]);

    useEffect(() => {
        if (feedbackResource && feedbackResource.myRole !== SharingRole.Owner) {
            const code = getQueryVariable('code');
            sendFeedbackPageEvent('sharing recipient landing', code ? 'link share' : 'email share');
        }
    }, [feedbackResource]);

    if (isLoading || !feedbackResource) {
        return <Loader title="Loading..." devLabel="collaboration API" />;
    }

    return (
        <CollaborationAbilityProvider resource={feedbackResource}>
            <FeedbackHeaderRow>
                <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold' }}>
                    {feedbackResource.design.designName}
                </Typography>
                <FeedbackAction
                    cid={feedbackResource.referenceId}
                    resourceId={resourceId}
                    myRole={feedbackResource.myRole}
                />
            </FeedbackHeaderRow>
            <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                    <Stack direction="row" gap={3} height="100%">
                        <FeedbackPreview previews={feedbackPreviews} />
                    </Stack>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Stack gap={5}>
                        {designStatus?.status === DesignStatusEnum.Approved && <FeedbackCantCommentInfo />}
                        <FeedbackStatus designStatus={designStatus} />
                        <FeedbackComment
                            comment={comment}
                            onChange={setComment}
                            sendComment={sendComment}
                            clearComment={clearComment}
                        />
                        <FeedbackTimeline
                            events={events}
                            loading={isFetching}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </CollaborationAbilityProvider>
    );
}
