import { Button, CheckIcon } from '@customink/pigment-react';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { copyToClipboard } from '~/adapters/browser/clipboard/copyToClipboard';
import { ContentCopy } from '@mui/icons-material';
import { ButtonProps } from '@customink/pigment-react/lib/components/Button/Button';

type CopyValueButtonProps = {
    valueToCopy: string;
    doneIcon?: ReactNode;
    doneChildren?: ReactNode;
} & ButtonProps;

/**
 * Provides default UX for "click this button to copy a value" scenario, e.g. for copying URL. <br>
 * Renders `startIcon` and `children` on default.
 * After `onClick`, saves the `valueToCopy` to clipboard and renders `doneIcon` and `doneChildren` for 1 second before
 * switching to the default render again.
 */
export function CopyValueButton({
    children = 'Copy',
    valueToCopy,
    onClick,
    startIcon = <ContentCopy />,
    doneIcon = <CheckIcon />,
    doneChildren = children,
    ...props
}: CopyValueButtonProps) {
    const [isDone, setIsDone] = useState(false);

    const handleOnClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            copyToClipboard(valueToCopy);
            setIsDone(true);
            setTimeout(() => {
                setIsDone(false);
            }, 1000);
            onClick?.(e);
        },
        [onClick],
    );

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button startIcon={isDone ? doneIcon : startIcon} onClick={handleOnClick} {...props}>
            {isDone ? doneChildren : children}
        </Button>
    );
}
