import React from 'react';
import { Button, ExitIcon, Stack, Tooltip, Typography } from '@customink/pigment-react';
import { Badge } from '@mui/icons-material';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { useAuth, useAuthUser } from '~/contexts/Auth/AuthContext';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useMediaQuery, useTheme } from '@mui/material';
import { Impersonate } from '../Impersonate/Impersonate';

const LogoutButton = styled(Button)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('lg')]: {
        display: 'block',
    },
}));

const ButtonTypography = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 4,
}));

const WelcomeBackUser: React.FC = () => {
    const { logout } = useAuth();
    const { emailDerivedName, isInternal } = useAuthUser();
    const { user: userData } = useUserAccount();

    const welcomeName = userData.firstName || emailDerivedName;

    return (
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography variant="h5" sx={{ fontWeight: 400 }} id="accounts-greeting">
                Welcome back, {welcomeName}!
                {isInternal && (
                    <Typography variant="subHeading" sx={{ pl: 1, verticalAlign: 'bottom' }}>
                        <Tooltip placement="top" title="Internal User">
                            <Badge color="secondary" />
                        </Tooltip>
                    </Typography>
                )}
            </Typography>
            <LogoutButton onClick={logout} data-action="logout" variant="secondary">
                <ButtonTypography variant="buttonLg" sx={{ fontWeight: 'bold', color: 'inherit' }}>
                    <ExitIcon fontSize="inherit" /> Log Out
                </ButtonTypography>
            </LogoutButton>
        </Stack>
    );
};

const WelcomeTopMenu: React.FC = () => {
    const theme = useTheme();
    const showDesktopMenu = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Stack gap={3} sx={{ pl: { lg: 2 } }}>
            {showDesktopMenu && <WelcomeBackUser />}
            <Impersonate />
        </Stack>
    );
};

export default WelcomeTopMenu;
