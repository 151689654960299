import { AuthMode, useApiCall } from '~/adapters/services/shared/apiCall';
import { getEnv } from '~/adapters/config/env';
import { Config } from '~/adapters/config/config';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { name } from '../../../../package.json';

export const useAccountsApiCall = () => {
    const { identityProvider } = useAuth();

    const auth: AuthMode = identityProvider === 'auth0' ? { withAccessToken: true } : { withIdToken: true };

    return useApiCall(getEnv('ACCOUNTS_ACCOUNTS_SERVICE_URL'), {
        auth,
        attachImpersonate: false,
        headers: { 'client-name': name, 'client-version': Config.VITE_RELEASE },
    });
};
