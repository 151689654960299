import {
    Button,
    DialogLegacyActions,
    DialogLegacyContent,
    IconButton,
    TextField,
    Typography,
    XCloseIcon,
} from '@customink/pigment-react';
import React, { FormEventHandler, useCallback } from 'react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { DialogTitle } from '@mui/material';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { useCreateTeamContext } from '~/components/MultiAccountManagement/Wizard/CreateTeamContext';

interface TeamNameStepProps {
    onSubmit: () => void;
    onClose: EmptyFunction;
    totalSteps: number;
}

function CloseIcon({ onClose }: { onClose: React.MouseEventHandler }) {
    return (
        <IconButton size="large" onClick={onClose as React.MouseEventHandler} aria-label="Close">
            <XCloseIcon fontSize="inherit" />
        </IconButton>
    );
}

export function TeamNameStep({ onClose, onSubmit, totalSteps }: TeamNameStepProps) {
    const { teamName, setTeamName } = useCreateTeamContext();

    const handleTeamCreated = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
        e.preventDefault();
        onSubmit();
    }, []);

    return (
        <form onSubmit={handleTeamCreated}>
            <DialogTitle>
                Create a Team
                <CloseIcon onClose={onClose} />
            </DialogTitle>
            <DialogLegacyContent>
                <TextField
                    autoFocus
                    id="name"
                    label="Team Name"
                    required
                    fullWidth
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                />
            </DialogLegacyContent>
            <DialogLegacyActions>
                <Typography sx={{ flexGrow: 1, color: designTokens.color.gray.limestone.value }}>
                    1 of {totalSteps}
                </Typography>
                <Button onClick={onClose} variant="secondary">
                    Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={!teamName}>
                    Create a team
                </Button>
            </DialogLegacyActions>
        </form>
    );
}
