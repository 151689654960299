import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Alert, Button, Dialog, DialogActions, DialogContent, Typography } from '@customink/pigment-react';
import { trackEvent } from '~/adapters/tracking/tracking';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import Spinner from '~/components/Spinner';
import {
    SharedWithMeKey,
    useUnsubscribeFromResourceMutation,
} from '~/adapters/services/collaboration/collaborationHooks';

function sendUnsubscribeEvent(label: string): void {
    trackEvent({ category: 'Modal', action: 'Button Click', label });
}

interface DeleteDialogProps {
    name: string;
    id: string;
    open: boolean;
    onClose: EmptyFunction;
}

export function DeleteDialog({ name, id, open, onClose }: DeleteDialogProps) {
    const client = useQueryClient();
    const { mutateAsync: unsubscribeAsync, isPending } = useUnsubscribeFromResourceMutation({
        onSuccess: () => client.invalidateQueries({ queryKey: [SharedWithMeKey] }),
    });

    const handleRemoveDesign = useCallback(async () => {
        sendUnsubscribeEvent('remove');
        const result = await unsubscribeAsync({ input: { resourceId: id } });

        if (result.unsubscribeFromResource) {
            pushSuccessNotification(`Design “${name}” has been successfully removed from your account.`);
        } else {
            pushApiErrorNotification(`An error occurred while trying to remove “${name}”.`);
        }

        onClose();
    }, [id, name, onClose]);

    return (
        <Dialog title="Delete This Design" open={open} onClose={onClose}>
            <DialogContent pt={2}>
                <Alert severity="error" sx={{ mb: 3 }}>
                    <Typography variant="bodyLong1">
                        This will permanently remove “{name}” from your account.
                    </Typography>
                </Alert>
                <Typography variant="bodyLong1">
                    You will no longer be able to edit or view this design. To regain access, the design owner will need
                    to share it with you again.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRemoveDesign} variant="primary" disabled={isPending}>
                    {isPending ? <Spinner /> : 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
