export type CertificateStatus = 'valid' | 'pending' | 'expired' | 'invalid' | 'revoked';

type ECMCertificateStatus = 'COMPLETE' | 'PENDING' | 'PENDING-FUTURE' | 'PENDING-MULTI' | 'PENDING-SINGLE' | 'REVOKED';

export interface ECMCertificateDto {
    id: number;
    accountId: number;
    signedDate: string;
    expirationDate: string;
    status: ECMCertificateStatus;
    cinkStatus: CertificateStatus;
    valid: boolean;
    /**
     * Country where the certificate is valid
     * ISO 3166-1 alpha-2 country code
     */
    exposureZoneCountry: string;
    /**
     * Region where the certificate is valid
     * ISO 3166 region, province, or state
     * For US, this is the ISO 3166-2:US region code
     */
    exposureZoneRegion: string;
    exemptionReason: string | null;
    attachmentUrl: string | undefined;
    thumbnailUrl: string | undefined;
    createdAt: string;
    updatedAt: string;
}

export type ECMCertificate = ReturnType<typeof transformECMCertificateDto>;

export function transformECMCertificateDto({ status, ...certificate }: ECMCertificateDto) {
    return {
        ...certificate,
        attachmentUrl: certificate.attachmentUrl ?? undefined,
        thumbnailUrl: certificate.thumbnailUrl ?? undefined,
        expirationDate: new Date(certificate.expirationDate),
        signedDate: new Date(certificate.signedDate),
        createdAt: new Date(certificate.createdAt),
        updatedAt: new Date(certificate.updatedAt),
    };
}

export interface ECMCustomerDto {
    id: number;
    accountId: number;
    businessAddress: ECMBusinessAddressDto | null;
    name: string;
    emailAddress: string | null;
    createdAt: string;
    updatedAt: string;
}

export interface ECMBusinessAddressDto {
    /**
     * ISO 3166-1 alpha-2 country code
     */
    country: string;
    /**
     * ISO 3166-2:US region code
     */
    region: string;
    line1: string;
    line2: string | null;
    city: string;
    postalCode: string;
    updatedAt: string;
}

export type BusinessAddress = Omit<ECMBusinessAddressDto, 'updatedAt'>;
export type BusinessAddressWName = BusinessAddress & { organizationName: string };

export type CertExpressInvitationDto =
    | {
          status: 'Ready';
          invitation: {
              requestLink: string;
              id: number;
          };
      }
    | {
          status: 'InProgress';
          invitation: {
              requestLink: never;
              id: number;
          };
      };
