import { FC } from 'react';
import { useGaInitializer } from '~/contexts/GaProvider';
import { useContextProviderStatusLogging } from '~/contexts/contextProviderLogger';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { PWC } from '~/adapters/typescript/propsWithChildren';

/**
 * Loads all global blocking (async) contexts in parallel. Then renders their providers in React manner, as part of the React tree.
 * @param children children to render once all contexts have loaded
 */
export const GlobalBlockingContextProviders: FC<PWC> = ({ children }) => {
    const [isGaReady] = useGaInitializer();

    const isReadyArray = [isGaReady];
    const isEverythingReady = isReadyArray.every(Boolean);

    useContextProviderStatusLogging('Global', isEverythingReady);

    if (!isEverythingReady) {
        return <FullLayoutLoader devLabel="Global Blocking Contexts" />;
    }

    return <>{children}</>;
};
