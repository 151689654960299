import { useEffect } from 'react';
import { trackPageView } from './tracking';

export function useTrackPage(title: string, trackPath: string, accountCompoundId?: string) {
    useEffect(() => {
        if (accountCompoundId === undefined) {
            return;
        }
        trackPageView(title, trackPath, accountCompoundId);
    }, [trackPath, accountCompoundId, title]);
}
