import { TableCell, TableRow } from '@mui/material';
import { SignalerFeatureFlagKey } from '~/adapters/signaler/signaler';
import { Variations } from './Variations';
import { CurrentVariation } from './CurrentVariation';

interface FeatureProps {
    featureKey: SignalerFeatureFlagKey;
    featureVariations: string[];
}

export function FeatureRow({ featureKey, featureVariations }: FeatureProps) {
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {featureKey}
            </TableCell>
            <TableCell>
                <Variations variations={featureVariations} featureKey={featureKey} />
            </TableCell>
            <TableCell>
                <CurrentVariation featureKey={featureKey} />
            </TableCell>
        </TableRow>
    );
}
