import { signaler, SignalerFeatureFlagKey } from '~/adapters/signaler/signaler';
import { useMemo } from 'react';
import { useSignalerContext } from '~/contexts/SignalerContext';

/*
 * This hook is used to check if a feature flag value is "test". It automatically rerenders the app when the feature flag is changed.
 */
export const useFeatureFlagEnabled = (flag: SignalerFeatureFlagKey) => {
    const { version } = useSignalerContext();
    return useMemo(() => (signaler.featureFlag(flag) as string) === 'test', [version]);
};
