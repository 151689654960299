import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { useMemo } from 'react';
import { QueryUsersResponse, User } from '~/adapters/services/accounts/users/types';
import { normalizeUser } from '~/adapters/services/accounts/users/transformers/userTransformer';
import { AccountDto } from '~/adapters/services/accounts/accounts/types';
import { Account, transformAccountDtoToAccount } from '~/contexts/UserAccount/account';

export type GetUsersParams =
    | {
          email: string;
      }
    | {
          uuid: string;
      };

export interface UpdateUserDataProps {
    userId: number;
    firstName: string;
    lastName: string;
}

class UsersRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getUserData = async (params: GetUsersParams) => {
        const response = await this.accountsApiCall<QueryUsersResponse>(`/users`, {
            params,
        });
        return normalizeUser(response.data[0]);
    };

    public getUserAccounts = async (userId: number): Promise<Account[]> => {
        const response = await this.accountsApiCall<AccountDto[]>(`/users/${userId}/accounts`);
        return response.data.map(transformAccountDtoToAccount);
    };

    public updateUserData = async ({ userId, ...userUpdateData }: UpdateUserDataProps) => {
        const response = await this.accountsApiCall<User>(`/users/${userId}`, {
            method: 'PUT',
            data: userUpdateData,
        });
        return normalizeUser(response.data);
    };
}

export const useUsersRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new UsersRepository(accountsApiCall), []);
};
