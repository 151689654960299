import { Typography } from '@customink/pigment-react';
import { signaler, SignalerFeatureFlagKey } from '~/adapters/signaler/signaler';

interface CurrentVariationProps {
    featureKey: SignalerFeatureFlagKey;
}

export function CurrentVariation({ featureKey }: CurrentVariationProps) {
    const currentVariation = signaler.featureFlag(featureKey);

    return <Typography variant="bodyShort1">{currentVariation}</Typography>;
}
