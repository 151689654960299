import { Typography } from '@mui/material';
import { Box } from '@customink/pigment-react';

export function StoreEmpty() {
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="bodyLong2">You haven&apos;t created any stores.</Typography>
        </Box>
    );
}
