import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent } from '@customink/pigment-react';
import { TextField } from '@mui/material';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Loader } from '~/components/Loader/loader';
import { useUpdateAccountName } from '~/adapters/services/accounts/accounts/hooks';

interface ChangeNameDialogProps {
    open: boolean;
    onClose: EmptyFunction;
    currentName: string | null;
    accountId: number;
}

interface ChangeNameForm {
    name: string;
}

const validateName = (value: string) => {
    if (value.trim() === '') {
        return "Account name can't be empty";
    }
    if (value.length > 100) {
        return 'Account name is too long';
    }
    return true;
};

export function ChangeNameDialog({ currentName, accountId, onClose, open }: ChangeNameDialogProps) {
    const { mutate, isPending } = useUpdateAccountName();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangeNameForm>({ defaultValues: { name: currentName ?? '' } });

    const onSubmit = useCallback<SubmitHandler<ChangeNameForm>>(
        ({ name }) => {
            mutate({ name, accountId }, { onSettled: onClose });
        },
        [mutate, accountId, onClose],
    );

    return (
        <Dialog open={open} onClose={onClose} title="Change account name" width="md" mobileVariant="fullScreen">
            <DialogContent pt={2}>
                <TextField
                    id="account-name"
                    label="Account name"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    autoFocus
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('name', { validate: validateName })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={isPending}>
                    {isPending ? <Loader title="Saving..." /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
