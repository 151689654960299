import { Control, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { BusinessAddressWName } from '~/adapters/services/accounts/taxExemptionsECM/types';
import { usStatesAndTerritoriesByCode, usStatesAndTerritoriesCodes } from '~/adapters/geography/usStatesAndTerritories';

/* eslint-disable react/jsx-props-no-spreading */

function SelectState({ control }: { control: Control<BusinessAddressWName> }) {
    return (
        <Controller
            name="region"
            control={control}
            rules={{ required: 'Please select a state.' }}
            render={({ field: { value = null, onChange, ...field }, formState: { errors } }) => (
                <Autocomplete
                    autoSelect
                    autoHighlight
                    options={usStatesAndTerritoriesCodes}
                    getOptionLabel={(option) => usStatesAndTerritoriesByCode[option] ?? option}
                    value={value}
                    onChange={(_event, newValue) => onChange(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="State"
                            placeholder="State"
                            inputProps={{ autoComplete: 'address-level1', ...params.inputProps }}
                            InputLabelProps={{ required: true, ...params.InputLabelProps }}
                            error={!!errors.region}
                            helperText={errors.region?.message}
                        />
                    )}
                    {...field}
                />
            )}
        />
    );
}

export default SelectState;
