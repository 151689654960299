import { getPager } from '~/adapters/browser/pager';
import { ApiCall, ApiCallError } from '~/adapters/services/shared/apiCall';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { useMemo } from 'react';
import { OffsetPaginationParams, offsetPaginationParams, SortParams } from '~/adapters/services/shared/createParams';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { Art } from './types';

export interface GetArtResponse {
    currentPage: number;
    totalCount: number;
    totalPages: number;
    hasMorePages: boolean;
    items: Art[];
}

export type ArtsSortBy = `${'+' | '-'}${'name' | 'createdAt' | 'updatedAt'}`;

export interface GetArtProps {
    accountId: number;
    sortBy: ArtsSortBy;
    perPage: number;
}

export interface RenameArtProps {
    uploadId: number;
    name: string;
}

export interface UploadArt {
    accountId: number;
    files: File[];
}

type ArtsQueryParam = OffsetPaginationParams & SortParams;

class ArtsRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getArts = async ({ pageParam = 1, accountId, perPage, sortBy }: PaginatedParams<GetArtProps>) => {
        const params: ArtsQueryParam = {
            sort: sortBy,
            ...offsetPaginationParams({ perPage, page: pageParam }),
        };

        const response = await this.accountsApiCall<Art[]>(`/accounts/${accountId}/art-locker/uploads`, { params });

        const { data, headers } = response;
        const pager = getPager(headers, pageParam);

        return {
            currentPage: pageParam,
            totalCount: pager.totalCount,
            totalPages: pager.totalPages,
            hasMorePages: pager.hasMorePages,
            items: data,
        };
    };

    public deleteArt = (uploadId: number) => {
        return this.accountsApiCall(`/art-locker/uploads/${uploadId}`, { method: 'DELETE' });
    };

    public renameArt = async ({ uploadId, name }: RenameArtProps) => {
        const response = await this.accountsApiCall<Art>(`/art-locker/uploads/${uploadId}`, {
            method: 'POST',
            data: { name },
            headers: { 'Content-Type': 'application/json' },
        });
        return response.data;
    };

    public uploadArts = ({ accountId, files }: UploadArt) => {
        return Promise.all(
            files.map(async (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return this.accountsApiCall<Art>(`/accounts/${accountId}/art-locker/uploads`, {
                    method: 'POST',
                    data: formData,
                }).catch((error: ApiCallError) => {
                    const errorMessage =
                        this.getErrorMessage(error) ??
                        `An error occurred while uploading the file “${file.name}”.<br/>Please try again later.`;
                    throw new Error(errorMessage);
                });
            }),
        );
    };

    /**
     * transforms error message to get information what was going wrong
     * if the response is not in desired shape, we dont care and simply swallow the parsing error and prints default error
     */
    private getErrorMessage(error?: ApiCallError): string | null {
        return (error?.response?.data as any)?.error ?? null;
    }
}

export const useArtsRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new ArtsRepository(accountsApiCall), []);
};
