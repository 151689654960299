/**
 * Tries to open the live chat. Return true if the chat was found & clicked on, or false otherwise.
 */
const openLiveChat = () => {
    const element = document.querySelector('.sb-LiveEngage-chat')?.childNodes?.[1]?.firstChild;
    (element as HTMLSpanElement)?.click();
    return !!element;
};

export const openLiveChatOrContact = () => {
    if (!openLiveChat()) {
        window.open('https://www.customink.com/contact', '_blank');
    }
};
