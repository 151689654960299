import { Button } from '@customink/pigment-react';
import { Design } from '~/adapters/services/accounts/designs/types';
import { internalBuyLink } from '~/adapters/services/rfe/links';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';

interface ReorderDesignButtonProps {
    design: Design;
}

export function InternalBuyButton({ design }: ReorderDesignButtonProps) {
    const isAddToCartProminenceEnabled = useFeatureFlagEnabled('add_to_cart_prominence_v0');
    const buttonLabel = design.mostRecentOrderId ? 'Internal Reorder' : 'Internal Checkout';

    return (
        <Button
            href={internalBuyLink(design.designId, design.compositeId, design.mostRecentOrderId)}
            variant={isAddToCartProminenceEnabled ? 'primary' : 'secondary'}
            fullWidth
            data-action="saved designs buy" // used by selenium_ui_tests
        >
            {buttonLabel}
        </Button>
    );
}
