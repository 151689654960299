import { Box } from '@customink/pigment-react';
import { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export const FilterId = 'page-filter';

export interface PageControlsPlaceHolderProps {
    pageControlsElementId?: string;
}

export function PageControlsPlaceHolder({ pageControlsElementId }: PageControlsPlaceHolderProps) {
    return <Box id={pageControlsElementId || FilterId} sx={{ flexGrow: 1, width: '100%' }} />;
}
type PageControlsProps = PWC<PageControlsPlaceHolderProps>;

export function PageControls({ children, pageControlsElementId }: PageControlsProps) {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);
    useLayoutEffect(() => {
        const usedElementId = pageControlsElementId || FilterId;
        const el = document.getElementById(usedElementId);
        if (!el) {
            throw new Error(`Element with ${usedElementId} ID not renderer`);
        }
        setWrapperElement(el);
    }, [pageControlsElementId]);
    if (!wrapperElement) {
        return null;
    }
    return ReactDOM.createPortal(children, wrapperElement);
}
