import { StoreGroupOrder } from '~/adapters/services/accounts/stores/types';
import { GroupOrder, GroupOrderProduct } from '~/adapters/services/accounts/groupOrders/types';

export const transformToGroupOrder = (storeGroupOrder: StoreGroupOrder): GroupOrder => {
    return {
        id: storeGroupOrder.id,
        name: storeGroupOrder.name,
        state: storeGroupOrder.status,
        compositeId: storeGroupOrder.urlSlug,
        totalQuantity: storeGroupOrder.totalItemsQuantity || 0,
        crowdsourced: storeGroupOrder.participantPay || false,
        totalPurchases: storeGroupOrder.totalPurchases || 0,
        expectedQuantity: storeGroupOrder.expectedQuantity || 0,
        endsOn: storeGroupOrder.targetDate || '',
        canceledAt: storeGroupOrder.canceledAt || null,
        createdAt: storeGroupOrder.createdAt,
        closedAt: storeGroupOrder.placedAt || null,
        placedAt: '',
        products: storeGroupOrder.products
            ? storeGroupOrder.products
                  .map((product) =>
                      product.variants.map<GroupOrderProduct>((variant) => ({
                          productId: variant.colorId,
                          isBackPrintable: product.isBackPrintable,
                          styleNo: product.styleId,
                          productNameAndColor: product.name,
                          isFrontPrintable: product.isFrontPrintable,
                      })),
                  )
                  .flat()
            : [],
    };
};
