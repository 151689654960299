import { Tab, Tabs } from '@customink/pigment-react';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { PageHeaderToggleLayout } from '~/components/PageHeader/PageHeaderToggleLayout';
import { useRouteTabs } from '~/components/hooks/useRouteTabs';
import { ArtsPath, DesignsPath } from '~/config';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { Config } from '~/adapters/config/config';
import { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SavedDesigns } from './SavedDesigns/SavedDesigns';
import Arts from './Arts/Arts';

export function DesignsAndArts() {
    const [selectedPath, selectPath] = useRouteTabs(DesignsPath, ArtsPath);
    const { account } = useUserAccount();

    const showToggleLayout = selectedPath === DesignsPath;
    const headerCopy = account.personal ? 'Your Designs' : 'Team Designs';

    const theme = useTheme();
    const matchesSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

    // There's been a problem on ArgosCI where the indicator was rendering different widht each time, so we're hiding it for now to make argosci builds more reliable
    const hideIndicator = useMemo(() => Config.MODE === 'test' && matchesSmallBreakpoint, [matchesSmallBreakpoint]);

    return (
        <>
            {showToggleLayout ? (
                <PageHeaderToggleLayout>{headerCopy}</PageHeaderToggleLayout>
            ) : (
                <PageHeader>{headerCopy}</PageHeader>
            )}
            <ErrorBoundary errorMessage="An error occurred while retrieving the designs">
                <Tabs
                    value={selectedPath}
                    onChange={(_, newVal) => selectPath(newVal)}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    TabIndicatorProps={{ sx: { display: hideIndicator ? 'none' : undefined } }}
                >
                    <Tab label="Designs" value={DesignsPath} />
                    <Tab label="Uploads" value={ArtsPath} />
                </Tabs>
                {selectedPath === DesignsPath && <SavedDesigns />}
                {selectedPath === ArtsPath && <Arts />}
            </ErrorBoundary>
        </>
    );
}
