import { ChangeEvent, useCallback, useEffect } from 'react';
import {
    Avatar,
    Box,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@customink/pigment-react';
import InputLabel from '@customink/pigment-react/lib/components/InputLabel';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Collapse, SelectChangeEvent } from '@mui/material';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { MultipleEmailInput } from '~/components/input/MultipleEmailInput';
import {
    ResponsiveWidthInputBox,
    ResponsiveFullWidthInputBox,
} from '~/components/ResponsiveInputBox/ResponsiveInputBox';
import { CopyLinkButton } from './components/CopyLinkButton';
import { useSharingAPI } from './SharingAPIContext';
import { LinkSharePermissionOptions, PermissionOptions, SharingTabProps } from './types';
import { permissionHasRole, PermissionMapper, SharingRoleMapper } from './mappers';
import { UserRoleSelect } from './components/UserRoleSelect';
import { sendSharingDialogEvent } from './sendSharingDialogEvent';

const linkPermissionMapper = (newPermission: LinkSharePermissionOptions) =>
    permissionHasRole(newPermission) ? PermissionMapper[newPermission] : null;

// the designToken value is in format '20px', need to convert it to number to multiply it
const bodyLong1LineHeight = parseInt(designTokens.typography.body.long1.lineHeight.value, 10);
// container that wraps texts anywhere, but only two lines, rest is clipped
const EmailWithAvatarContainer = styled(Stack)({
    overflowWrap: 'anywhere',
    lineHeight: bodyLong1LineHeight,
    maxHeight: 2 * bodyLong1LineHeight,
    overflowY: 'clip',
});

interface InviteFirstStepProps {
    linkPermission: LinkSharePermissionOptions;
    setLinkPermission: (value: LinkSharePermissionOptions) => void;
}

function InviteFirstStep({ linkPermission, setLinkPermission }: InviteFirstStepProps) {
    const { email: myEmail } = useAuthUser();
    const { createMutationPending, emailShares, linkShareRole, changeLinkSharing, copyLink } = useSharingAPI();

    // update LinkPermission with the SharingRole from query (replace local default value when we obtain api response)
    useEffect(() => {
        if (linkShareRole === undefined) {
            return;
        }

        const newPermission = SharingRoleMapper(linkShareRole);
        setLinkPermission(newPermission);
    }, [linkShareRole]);

    const handleLinkPermissionChange = (event: SelectChangeEvent<unknown>) => {
        const newPermission = event.target.value as LinkSharePermissionOptions;
        const newRole = linkPermissionMapper(newPermission);
        sendSharingDialogEvent('Select Change', 'Link sharing permission');
        setLinkPermission(newPermission);
        changeLinkSharing(newRole);
    };

    const copyLinkWithPermission = useCallback(
        () => copyLink(linkPermissionMapper(linkPermission)),
        [linkPermission, copyLink],
    );

    return (
        <>
            <Typography paragraph variant="subHeading" pb={1}>
                People With Access
            </Typography>

            <Grid container spacing={2}>
                <Grid item container justifyContent="space-between" flexWrap="nowrap">
                    <Grid item>
                        <EmailWithAvatarContainer direction="row" spacing={1}>
                            <Avatar data={myEmail} />
                            <Typography variant="bodyLong1">{myEmail} (You)</Typography>
                        </EmailWithAvatarContainer>
                    </Grid>
                    <Grid item>
                        <Box textAlign="right">
                            <Typography>Owner</Typography>
                        </Box>
                    </Grid>
                </Grid>
                {emailShares.map((user) => (
                    <Grid
                        item
                        container
                        key={user.email}
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="nowrap"
                        gap={2}
                    >
                        <Grid item>
                            <EmailWithAvatarContainer direction="row" spacing={1}>
                                <Avatar data={user.email} />
                                <Typography variant="bodyLong1">{user.email}</Typography>
                            </EmailWithAvatarContainer>
                        </Grid>
                        <Grid item>
                            <UserRoleSelect email={user.email} role={user.role} />
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            <Divider sx={{ my: 3 }} />
            <Typography paragraph variant="subHeading" mb={1}>
                Public Access
            </Typography>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2} gap={2}>
                <Typography>Anyone with the link</Typography>
                <ResponsiveWidthInputBox>
                    <Select
                        value={linkPermission}
                        onChange={handleLinkPermissionChange}
                        disabled={createMutationPending}
                    >
                        <MenuItem value="view">Can view</MenuItem>
                        <MenuItem value="comment">Can comment</MenuItem>
                        <MenuItem value="edit">Can edit</MenuItem>
                        <MenuItem value="noaccess">Can&apos;t access</MenuItem>
                    </Select>
                </ResponsiveWidthInputBox>
            </Stack>

            {linkPermission !== 'noaccess' && (
                <CopyLinkButton copyLink={copyLinkWithPermission} disabled={createMutationPending} />
            )}
        </>
    );
}

interface InviteSecondStepProps {
    emailMessage: string;
    setEmailMessage: (value: string) => void;
}

function InviteSecondStep({ emailMessage, setEmailMessage }: InviteSecondStepProps) {
    const handleEmailMessageChange = (event: ChangeEvent<HTMLInputElement>) => setEmailMessage(event.target.value);
    return (
        <TextField
            label="Message"
            value={emailMessage}
            onChange={handleEmailMessageChange}
            multiline
            minRows={2}
            fullWidth
        />
    );
}

export function SharingDialogInvite({ sharingState, amendState }: SharingTabProps) {
    const handleEmailPermissionChange = (event: SelectChangeEvent<unknown>) => {
        amendState({ emailPermission: event.target.value as PermissionOptions });
        sendSharingDialogEvent('Select Change', 'New email sharing permission');
    };

    const isEmailFieldEmpty = sharingState.emailRecipients.length === 0 && sharingState.emailInputValue.length === 0;

    return (
        <Box>
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                alignItems="baseline"
                spacing={2}
                justifyContent="space-between"
                mb={3}
            >
                <MultipleEmailInput
                    emails={sharingState.emailRecipients}
                    setEmails={(value) => amendState({ emailRecipients: value })}
                    inputValue={sharingState.emailInputValue}
                    setInputValue={(value) => amendState({ emailInputValue: value })}
                    errorMessage={sharingState.emailInputErrorMessage}
                    setErrorMessage={(value) => amendState({ emailInputErrorMessage: value })}
                    dataAction="sharing dialog email input" // used by selenium_ui_tests
                />
                <ResponsiveFullWidthInputBox>
                    <FormControl fullWidth>
                        {/* to vertically align with MultipleEmailInput label */}
                        <InputLabel sx={{ display: { sm: 'none', md: 'inline' } }}>&nbsp;</InputLabel>
                        <Select value={sharingState.emailPermission} onChange={handleEmailPermissionChange}>
                            <MenuItem value="view">Can view</MenuItem>
                            <MenuItem value="comment">Can comment</MenuItem>
                            <MenuItem value="edit">Can edit</MenuItem>
                        </Select>
                    </FormControl>
                </ResponsiveFullWidthInputBox>
            </Stack>

            <Collapse in={isEmailFieldEmpty}>
                <InviteFirstStep
                    linkPermission={sharingState.linkPermission}
                    setLinkPermission={(value) => amendState({ linkPermission: value })}
                />
            </Collapse>
            <Collapse in={!isEmailFieldEmpty}>
                <InviteSecondStep
                    emailMessage={sharingState.emailMessage}
                    setEmailMessage={(value) => amendState({ emailMessage: value })}
                />
            </Collapse>
        </Box>
    );
}
