import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { parseFeatureFlags } from '~/adapters/browser/getCookie';
import { trackFeatureFlag } from '~/adapters/feature-flags/trackFeatureFlag';

export const featureFlagsVersions = {
    // we are working with keys in accounts-ui, value is what is in signalman
    // turns out it is necessary to specify the version.. Otherwise it can read old cookies, making it behave unpredictably
    collaboration: 'collaboration_v5',
    auth0: 'auth0_rollout',
    inker_ux_toggle: 'inker_ux_toggle',
};

type FeatureFlagKeys = keyof typeof featureFlagsVersions;

const flagsExcludedFromGA: FeatureFlagKeys[] = ['auth0'];

export type FeatureFlags = {
    [K in FeatureFlagKeys]?: string;
};

export const FeatureFlagSignalmanContext = createContext<FeatureFlags | null>(null);

export const isFeatureFlagEnabled = (featureFlagVariation?: string): boolean => featureFlagVariation === 'test';

export function useSignalmanFeatureFlag(featureFlagKey: FeatureFlagKeys): boolean {
    const currentFeatureFlags = useContext(FeatureFlagSignalmanContext);
    if (!currentFeatureFlags) {
        throw new Error('Feature flag context not provided');
    }
    return isFeatureFlagEnabled(currentFeatureFlags[featureFlagKey]);
}

export function FeatureFlagSignalmanProvider({ children }: PropsWithChildren<unknown>) {
    const featureFlagState = useMemo<FeatureFlags>(() => parseFeatureFlags(featureFlagsVersions), []);

    // track all Signalman feature flags
    useEffect(() => {
        Object.entries(featureFlagState ?? {}).forEach(([key, value]) => {
            if (value && !flagsExcludedFromGA.includes(key as FeatureFlagKeys)) {
                trackFeatureFlag(key, value);
            }
        });
    }, [featureFlagState]);

    return (
        <FeatureFlagSignalmanContext.Provider value={featureFlagState}>{children}</FeatureFlagSignalmanContext.Provider>
    );
}
