import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getImpersonateQueryVariable } from '~/adapters/browser/getImpersonateQueryVariable';
import { useAuth } from '~/contexts/Auth/AuthContext';
import * as Sentry from '@sentry/react';

type AccessTokenMode = {
    withAccessToken: true;
    withIdToken?: false;
};

type IdTokenMode = {
    withAccessToken?: false;
    withIdToken: true;
};

type AnonymousMode = {
    withAccessToken?: false;
    withIdToken?: false;
};

export type AuthMode = AccessTokenMode | IdTokenMode | AnonymousMode;

type ImpersonateOptions = { attachImpersonate: boolean };

type CreateApiCallOptions = Omit<AxiosRequestConfig, 'auth'> & ImpersonateOptions & { auth?: AuthMode };

export type ApiCallOptions = AxiosRequestConfig & Partial<ImpersonateOptions> & { isPublic?: boolean };
export type ApiCallResponse<T = unknown> = AxiosResponse<T>;
export type ApiCallError = AxiosError;
export type ApiCall = <T>(url: string, options?: ApiCallOptions) => Promise<ApiCallResponse<T>>;

/**
 * Create configurable axios API call function for a single API service.
 * @param baseUrl base URL of the API service
 * @param createApiCallOptions axios request config + the auth mode supported by the given API service
 */
export const useApiCall = (baseUrl: string, createApiCallOptions: CreateApiCallOptions): ApiCall => {
    const axiosInstance = axios.create();
    const { getTokens, identityProvider, user } = useAuth();
    const isAuthenticated = Boolean(user);

    const getOptionalTokens = async () =>
        isAuthenticated ? getTokens() : { idToken: undefined, accessToken: undefined };

    /**
     * axios API call function
     * @param path path to a single API endpoint
     * @param currentOptions (ApiCallOptions) axios request config for the specific request + impersonate options + isPublic option
     */
    return async (path, currentOptions = {}) => {
        const { auth: authMode, headers: defaultHeaders, ...defaultReqOptions } = createApiCallOptions;
        const { headers: currentHeaders, data, attachImpersonate, isPublic, ...currentReqOptions } = currentOptions;

        const urlWithPrefix = baseUrl + path;
        const withAccessToken = authMode?.withAccessToken ?? false;
        const withIdToken = authMode?.withIdToken ?? false;

        const mergedOptions = { ...defaultReqOptions, ...currentReqOptions };

        const shouldAttachImpersonate = attachImpersonate ?? createApiCallOptions.attachImpersonate;
        const params = new URLSearchParams(mergedOptions.params);
        const impersonate = getImpersonateQueryVariable();
        if (shouldAttachImpersonate && impersonate) {
            params.append('impersonate', impersonate);
        }

        const reportToSentry = (tokenType: string) =>
            Sentry.captureMessage(`Missing ${tokenType} in request to ${urlWithPrefix}`, {
                extra: { params, identityProvider },
            });
        const getAuthorizationHeader = async () => {
            const { accessToken, idToken } = await getOptionalTokens();
            if (withAccessToken) {
                if (accessToken) {
                    return `Bearer ${accessToken}`;
                }
                if (isPublic) {
                    return undefined;
                }
                reportToSentry('access token');
            } else if (withIdToken) {
                if (idToken) {
                    return `Bearer ${idToken}`;
                }
                if (isPublic) {
                    return undefined;
                }
                reportToSentry('id token');
            }
            return undefined;
        };
        const mergedHeaders = { ...defaultHeaders, ...currentHeaders, Authorization: await getAuthorizationHeader() };

        return axiosInstance.request({ headers: mergedHeaders, ...mergedOptions, url: urlWithPrefix, data, params });
    };
};
