import { useTheme } from '@mui/material';
import { useState } from 'react';

export function CarouselProductImage({
    alt,
    src,
    initialAspectRatio = '1 / 0.95',
}: {
    alt: string;
    src: string;
    initialAspectRatio?: string;
}) {
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();
    return (
        <img
            onLoad={() => setLoaded(true)}
            alt={alt}
            src={src}
            key={src}
            style={{
                width: 'inherit',
                aspectRatio: loaded ? 'unset' : initialAspectRatio,
                background: theme.palette.grey[300],
                transition: 'height 0.5s',
                textAlign: 'center', // for the alt text
                color: theme.palette.grey[500],
            }}
        />
    );
}
