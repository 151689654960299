import Spinner from '~/components/Spinner';
import { Box, Typography } from '@customink/pigment-react';

/** Arbitrary value taken from Figma. Ideally move this to Pigment ad codify as design decision 🙏 */
const paragraphMaxWidth = 704;

export function LoadingTaxExemptions() {
    return (
        <Box mt={5} sx={{ maxWidth: paragraphMaxWidth }}>
            <Spinner size={40} />
            <Typography fontSize="28px" lineHeight="35px" fontWeight="700" variant="h2">
                Loading Your Tax Exemption Portal
            </Typography>
            <Typography component="p" variant="bodyShort2" mt={2}>
                This process usually takes just a few moments. Your tax exemption portal will open automatically once
                it&apos;s ready.
            </Typography>
        </Box>
    );
}
