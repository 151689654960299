import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Config } from '~/adapters/config/config';
import { AsyncContextLoader } from '~/contexts/types';

export const useGaInitializer: AsyncContextLoader = () => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        ReactGA.initialize(Config.ACCOUNTS_GA);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.ga = ReactGA.ga();
        setReady(true);
    }, []);

    return [ready];
};
