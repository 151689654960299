import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { useMemo } from 'react';
import { getPager } from '~/adapters/browser/pager';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import {
    HealthAndWellnessData,
    Store,
    StoreData,
    StoreGroupOrder,
    StoreGroupOrderResponse,
    StoreOrderSummary,
} from '~/adapters/services/accounts/stores/types';
import { transformToGroupOrder } from '~/adapters/services/accounts/stores/transformers';

export interface GetStoresProps {
    accountId: number;
}

const PER_PAGE = 6;

class StoresRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getHealthAndWellnessOrders = async ({
        pageParam = 1,
        accountId,
    }: PaginatedParams<GetStoresProps>): Promise<HealthAndWellnessData> => {
        const params = {
            ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
        };

        const response = await this.accountsApiCall<StoreOrderSummary[]>(
            `/accounts/${accountId}/stores/purchases/health-and-wellness`,
            {
                params,
            },
        );

        const { data, headers } = response;
        const pagination = getPager(headers, pageParam, PER_PAGE);

        return {
            orders: data,
            hasMorePages: pagination.hasMorePages,
            currentPage: pageParam,
        };
    };

    public getStores = async ({ pageParam = 1, accountId }: PaginatedParams<GetStoresProps>): Promise<StoreData> => {
        const params = {
            ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
        };

        const response = await this.accountsApiCall<Store[]>(`/accounts/${accountId}/stores`, {
            params,
        });

        const { data, headers } = response;
        const userInvitationToken: string = headers['x-user-invitation-token'];
        const pagination = getPager(headers, pageParam, PER_PAGE);

        return {
            stores: data,
            userInvitationToken: userInvitationToken === 'false' ? null : userInvitationToken,
            hasMorePages: pagination.hasMorePages,
            currentPage: pageParam,
        };
    };

    public getStoresGroupOrders = async ({
        pageParam = 1,
        accountId,
    }: PaginatedParams<GetStoresProps>): Promise<StoreGroupOrderResponse> => {
        const params = {
            ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
        };

        const response = await this.accountsApiCall<StoreGroupOrder[]>(`/accounts/${accountId}/stores/gof`, {
            params,
        });

        const { data, headers } = response;
        const pagination = getPager(headers, pageParam, PER_PAGE);

        return {
            groupOrders: data.map((go) => ({ ...transformToGroupOrder(go), isStoreGroupOrder: true })),
            hasMorePages: pagination.hasMorePages,
            currentPage: pageParam,
        };
    };

    public getStoreGroupOrdersCount = async ({ accountId }: GetStoresProps): Promise<number> => {
        const params = { limit: 1, offset: 0 };
        const response = await this.accountsApiCall(`/accounts/${accountId}/stores/gof`, { params });
        return Number(response.headers['x-total-count'] ?? 0);
    };
}

export const useStoresRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new StoresRepository(accountsApiCall), []);
};
