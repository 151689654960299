import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { useMemo } from 'react';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { getPager } from '~/adapters/browser/pager';
import {
    Design,
    InfiniteDesignProps,
    PriceQuoteResponse,
    ProductsDetailsResponse,
} from '~/adapters/services/accounts/designs/types';

export interface GetDesignResponse {
    currentPage: number;
    totalCount: number;
    totalPages: number;
    hasMorePages: boolean;
    items: Design[];
}

interface DeleteDesignProps {
    accountId: number;
    cid: string;
}

class DesignsRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public deleteDesign = async ({ cid, accountId }: DeleteDesignProps) => {
        return this.accountsApiCall(`/accounts/${accountId}/designs/${cid}/hide`, { method: 'POST' });
    };

    public getDesigns = async ({
        accountId,
        pageParam = 1,
        sort,
    }: PaginatedParams<InfiniteDesignProps>): Promise<GetDesignResponse> => {
        const params = {
            sort,
            ...offsetPaginationParams({ page: pageParam, perPage: 6 }),
        };

        const { data, headers } = await this.accountsApiCall<Design[]>(`/accounts/${accountId}/designs`, {
            params,
        });
        const { totalCount, totalPages, hasMorePages } = getPager(headers, pageParam);
        return {
            currentPage: pageParam,
            totalCount,
            totalPages,
            hasMorePages,
            items: data,
        };
    };

    public getDesignSummary = async (cid: string): Promise<Design> => {
        const { data } = await this.accountsApiCall<Design>(`/designs/${cid}/summary`, { isPublic: true });
        return data;
    };

    public getDesignQuote = async (cid: string): Promise<PriceQuoteResponse> => {
        const { data } = await this.accountsApiCall<PriceQuoteResponse>(`/designs/${cid}/quote`);
        return data;
    };

    public getProductDetails = async (cid: string): Promise<ProductsDetailsResponse> => {
        const { data } = await this.accountsApiCall<ProductsDetailsResponse>(`/designs/${cid}/products`);
        return data;
    };
}

export const useDesignsProxyRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new DesignsRepository(accountsApiCall), []);
};
