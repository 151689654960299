import { Button, Container, Typography } from '@customink/pigment-react';
import { CushionedBox } from '~/components/Layout/CushionedBox';

interface Props {
    designLabLink: string;
}

export function EmptyDesignList({ designLabLink }: Props) {
    return (
        <CushionedBox>
            <Container maxWidth="md">
                <Typography variant="bodyShort2" paragraph>
                    You have no saved designs.
                    <br />
                    Visit the Design Lab to get started!
                </Typography>
                <Button href={designLabLink}>Start designing</Button>
            </Container>
        </CushionedBox>
    );
}
