import { useMutation } from '@tanstack/react-query';
import { useAuth0Repository } from '~/adapters/services/accounts/auth0/rest';
import timeoutPromise from '~/adapters/browser/timeoutPromise';
import { pushErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';

export function useVerifyUser() {
    const { verifyUser } = useAuth0Repository();

    return useMutation({
        mutationFn: verifyUser,
        onSuccess() {
            pushSuccessNotification('Verification email resent, please check your inbox.');
            return timeoutPromise(60000, undefined);
        },
        onError() {
            pushErrorNotification("Couldn't resend verification email. Please login again in a different tab.");
        },
    });
}
