import { Stack } from '@customink/pigment-react';
import { VerifiedUser } from '@mui/icons-material';
import { StackProps, Typography } from '@mui/material';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import * as Sentry from '@sentry/react';

export function ImpersonateOnlyBanner(props: StackProps) {
    const { impersonated } = useAuthUser();
    if (!impersonated) {
        Sentry.captureMessage(
            'Component `ImpersonateOnlyBanner` has been rendered although the user is not impersonated. This is probably a mistake.',
            {
                level: 'error',
            },
        );
        return null;
    }
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Stack {...props} direction="row" spacing={1} alignItems="center">
            <VerifiedUser color="secondary" />
            <Typography variant="captionLarge">Only impersonating Inkers can see this</Typography>
        </Stack>
    );
}
