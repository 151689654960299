import { OrderLinks, OrderSummary } from '~/adapters/services/accounts/orders/types';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { useLinks } from '~/contexts/LinkContext';

export const useOrderLinks = (order: OrderSummary): OrderLinks => {
    const { isImpersonatedExperience } = useAuth();
    const { rfeLink } = useLinks();

    const designUrl = isImpersonatedExperience
        ? `${rfeLink}/admin/designs/${order.designId}`
        : `${rfeLink}/designs/${order.designName}/${order.designId}-${order.accountId}/retrieve`;

    const reorderUrl = isImpersonatedExperience
        ? `${rfeLink}/admin/internal_checkouts/create_reorder_ap_order?design_id=${order.designId}&reorder=${order.id}`
        : `${rfeLink}/checkout/reorder?fulfillment_order_id=${order.id}`;

    const trackOrderUrl = `${rfeLink}/track/${order.id}-${order.accountId}`;

    const invoiceUrl = `${rfeLink}/track/${order.id}-${order.accountId}/invoice`;

    return {
        designUrl,
        reorderUrl,
        trackOrderUrl,
        invoiceUrl,
    };
};
