import { Theme, ThemeOptions, ThemeProvider, useTheme } from '@mui/material';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { useMemo } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

// Cody of this theme has been copy pasted as-is from Pigment repo, from an old branch.
// TODO (p.kratochvil, a.teichmann, j.zbytovsky) remove this after all old cards are redesigned and rolled out to 100% (Shared Cards, GOFs, Arts)
const designCardLegacyThemeCreator = (theme: Theme): ThemeOptions => {
    const cardDesignTokens = {
        innerPadding: theme.spacing(1.5, 2),
        boxShadow: '0 0 10px 0 rgba(97, 95, 95, 0.35)',
    };
    return {
        components: {
            MuiCard: {
                defaultProps: {
                    variant: 'outlined',
                },
                styleOverrides: {
                    root: {
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            boxShadow: designTokens.shadow.medium.value,
                        },
                    },
                },
                variants: [],
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        padding: cardDesignTokens.innerPadding,
                        display: 'block',
                    },
                    title: {
                        color: designTokens.color.gray.slateDefault.value,
                        fontSize: '1.5rem',
                        fontWeight: designTokens.typography.heading.sub.fontWeight.value,
                        lineHeight: '1.2',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        wordWrap: 'normal',
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        color: designTokens.color.gray.slateDefault.value,
                        padding: cardDesignTokens.innerPadding,
                        textAlign: 'center',
                        fontFamily: theme.typography.fontFamily,
                    },
                },
            },
            MuiCardMedia: {
                styleOverrides: {
                    root: {
                        position: 'relative',
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: cardDesignTokens.innerPadding,
                        flexDirection: 'column',
                        '> :not(:first-of-type)': {
                            margin: 'unset',
                        },
                        gap: theme.spacing(1),
                    },
                },
            },
        },
    };
};

export function OldCardThemeProvider({ children }: PWC) {
    const theme = useTheme();
    const oldTheme = useMemo(
        () => ({
            ...theme,
            components: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...theme.components,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...designCardLegacyThemeCreator(theme).components,
            },
        }),
        [theme],
    );
    return <ThemeProvider theme={oldTheme}>{children}</ThemeProvider>;
}
