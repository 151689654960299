import { Stack, Typography } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { ResponsiveFullWidthInputBox } from '~/components/ResponsiveInputBox/ResponsiveInputBox';

export function PageSort({ children }: PWC) {
    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            <Typography variant="bodyLong1" sx={{ whiteSpace: 'nowrap' }}>
                Sort by:
            </Typography>
            <ResponsiveFullWidthInputBox>{children}</ResponsiveFullWidthInputBox>
        </Stack>
    );
}
