import { useEffect } from 'react';
import { log } from '~/adapters/browser/localLogger';

export function useContextProviderStatusLogging(contextProviderName: string, isReady: boolean) {
    useEffect(() => {
        if (!isReady) {
            log.info(`🧘 ${contextProviderName} context provider loading... ⏳`);
        } else {
            log.info(`🧘 ${contextProviderName} context provider is ready ✅`);
        }
    }, [isReady, contextProviderName]);
}
