import * as Sentry from '@sentry/react';
import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useMemo } from 'react';
import { useAnonymousAccountsApiCall } from '~/adapters/services/accounts/anonymousAccountsApiCall';

class Auth0Repository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public verifyUser = (sessionToken: string) => {
        if (!sessionToken) {
            Sentry.captureException(
                'Verify user should be always called with session token, but no sessionToken was given',
            );
        }
        return this.accountsApiCall(`/auth0/verify`, {
            method: 'POST',
            params: {
                sessionToken,
            },
        });
    };
}

export const useAuth0Repository = () => {
    const anonymousAccountsApiCall = useAnonymousAccountsApiCall();

    return useMemo(() => new Auth0Repository(anonymousAccountsApiCall), []);
};
