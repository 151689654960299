import { CertificateStatus, ECMCertificate } from '~/adapters/services/accounts/taxExemptionsECM/types';
import { useTheme, Chip } from '@mui/material';
import { Button, Grid, Stack, Tooltip, Typography, Card, CardContent, CardActions } from '@customink/pigment-react';
import { Circle } from '@mui/icons-material';
import { useStatusAdornments } from '~/pages/Settings/tabs/TaxExemptionECM/components/Certificate/statusTranslate';
import { usStatesAndTerritoriesByCode } from '~/adapters/geography/usStatesAndTerritories';
import { formatDate } from '~/adapters/date/date';
import { capitalize } from 'lodash';

interface CertificateManagementProps {
    certificates: Array<ECMCertificate>;
    certStatusFilter: CertificateStatus | 'all';
}

function Certificate({ certificate }: { certificate: ECMCertificate }) {
    const theme = useTheme();
    const statusAdornment = useStatusAdornments(certificate);
    return (
        <Grid item sm={12} md={6} lg={4} container>
            <Card size="small" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <CardContent sx={{ mt: 0, pt: 2.5, flexGrow: 1 }}>
                    <Stack direction="column" spacing={1.5} sx={{ py: 0, mb: 2 }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ width: '100%' }}
                        >
                            <Typography
                                variant="h5"
                                component="h4"
                                fontWeight={700}
                                sx={{ pr: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
                            >
                                {usStatesAndTerritoriesByCode[certificate.exposureZoneRegion] ??
                                    certificate.exposureZoneRegion}
                            </Typography>
                            <Tooltip placement="bottom" title={statusAdornment.tooltipMessage ?? ''}>
                                <Chip
                                    component="span"
                                    icon={<Circle width="50px" style={{ color: statusAdornment.color }} />}
                                    label={capitalize(certificate.cinkStatus)}
                                />
                            </Tooltip>
                        </Stack>
                    </Stack>
                    {/* TODO @teichmaa update all these Typographies once we have the new tokens https://customink.monday.com/boards/5878886849/views/128588597/pulses/7179095308 */}
                    {certificate.expirationDate && (
                        <Typography paragraph fontWeight={500} variant="bodyShort2">
                            {certificate.expirationDate.getFullYear() === 9999
                                ? 'Does Not Expire'
                                : `Expire${certificate.expirationDate < new Date() ? 'd' : 's'} on ${formatDate(
                                      certificate.expirationDate,
                                  )}`}
                        </Typography>
                    )}
                    <Typography
                        mb={0}
                        paragraph
                        textTransform="capitalize"
                        style={{ color: theme.palette.text.secondary }}
                    >
                        {certificate.exemptionReason?.toLowerCase() || 'General Use'}
                    </Typography>
                </CardContent>
                <CardActions style={{ flexDirection: 'row' }}>
                    <Button
                        target="_blank"
                        disabled={!certificate.attachmentUrl}
                        href={certificate.attachmentUrl}
                        variant="secondary"
                        size="small"
                    >
                        Download
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

export function CertificateManagement({ certificates, certStatusFilter }: CertificateManagementProps) {
    return (
        <Grid container spacing={2}>
            {certificates.length === 0 && (
                <Grid item container alignItems="center" justifyContent="center">
                    <Typography component="p" variant="bodyShort2">
                        You have no {certStatusFilter} certificates.
                    </Typography>
                </Grid>
            )}
            {certificates.map((certificate) => (
                <Certificate key={certificate.id} certificate={certificate} />
            ))}
        </Grid>
    );
}
