import { Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Typography } from '@mui/material';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { PageControlsPlaceHolder, PageControlsPlaceHolderProps } from '~/components/PageHeader/PageControls';

export const PageHeaderLayout = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

type PageHeaderProps = PWC<PageControlsPlaceHolderProps>;

export function PageHeader({ children, pageControlsElementId }: PageHeaderProps) {
    return (
        <PageHeaderLayout>
            <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold', pb: 2, whiteSpace: 'nowrap' }}>
                {children}
            </Typography>
            <PageControlsPlaceHolder pageControlsElementId={pageControlsElementId} />
        </PageHeaderLayout>
    );
}
