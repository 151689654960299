import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { useMemo } from 'react';
import { getPager } from '~/adapters/browser/pager';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { GroupOrder, GroupOrderResponse } from '~/adapters/services/accounts/groupOrders/types';

export interface GetGroupOrderProps {
    accountId: number;
}

const PER_PAGE = 6;

class GroupOrdersRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getGroupOrders = async ({
        pageParam = 1,
        accountId,
    }: PaginatedParams<GetGroupOrderProps>): Promise<GroupOrderResponse> => {
        const params = {
            ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
        };

        const response = await this.accountsApiCall<GroupOrder[]>(`/accounts/${accountId}/group-orders`, {
            params,
        });

        const { data, headers } = response;
        const pagination = getPager(headers, pageParam, PER_PAGE);

        return {
            groupOrders: data.map((groupOrder) => ({ ...groupOrder, isStoreGroupOrder: false })),
            hasMorePages: pagination.hasMorePages,
            currentPage: pageParam,
        };
    };

    public getGroupOrdersCount = async ({ accountId }: GetGroupOrderProps): Promise<number> => {
        const params = { limit: 1, offset: 0 };
        const response = await this.accountsApiCall(`/accounts/${accountId}/group-orders`, { params });
        return Number(response.headers['x-total-count'] ?? 0);
    };
}

export const useGroupOrdersRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new GroupOrdersRepository(accountsApiCall), []);
};
