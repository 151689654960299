import { useMemo } from 'react';
import { getPager } from '~/adapters/browser/pager';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { OrderDetails, OrderSortBy, OrderSummary } from '~/adapters/services/accounts/orders/types';
import { ApiCall } from '~/adapters/services/shared/apiCall';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';

interface OrdersData {
    orders: OrderSummary[];
    hasMorePages: boolean;
    currentPage: number;
}

export interface GetOrdersProps {
    accountId: number;
    sort: OrderSortBy;
}

export interface GetOrderProps {
    orderId: number;
}

class OrdersRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getOrdersForAccount = async ({
        pageParam = 1,
        accountId,
        sort,
    }: PaginatedParams<GetOrdersProps>): Promise<OrdersData> => {
        const params = {
            ...offsetPaginationParams({ perPage: 5, page: pageParam }),
            sort,
        };

        const response = await this.accountsApiCall<OrderSummary[]>(`/accounts/${accountId}/orders`, {
            params,
        });

        const { data, headers } = response;
        const pagination = getPager(headers, pageParam, 5);

        return {
            orders: data,
            hasMorePages: pagination.hasMorePages,
            currentPage: pageParam,
        };
    };

    public getOrderDetails = async ({ orderId }: GetOrderProps): Promise<OrderDetails> => {
        const response = await this.accountsApiCall<OrderDetails[]>(`/orders`, { params: { ids: orderId } });
        return response.data[0];
    };
}

export const useOrdersRepository = () => {
    const accountsApiCall = useAccountsApiCall();

    return useMemo(() => new OrdersRepository(accountsApiCall), []);
};
