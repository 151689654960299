import { Alert } from '@mui/material';
import { Typography } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';

const StyledAlert = styled(Alert)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.primary,
}));

export function FeedbackCantCommentInfo() {
    return (
        <StyledAlert icon={<></>}>
            <Typography variant="bodyShort1">
                This design is approved, and it no longer accepts feedback. <b>jon.hamilton@gmail.com</b> will be
                notified momentarily.
            </Typography>
        </StyledAlert>
    );
}
