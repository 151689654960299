import { AuthMode, useApiCall } from '~/adapters/services/shared/apiCall';
import { getEnv } from '~/adapters/config/env';
import { Config } from '~/adapters/config/config';
import { name } from '../../../../package.json';

export const useAnonymousAccountsApiCall = () => {
    const anonymousMode: AuthMode = {};

    return useApiCall(getEnv('ACCOUNTS_ACCOUNTS_SERVICE_URL'), {
        auth: anonymousMode,
        attachImpersonate: false,
        headers: { 'client-name': name, 'client-version': Config.VITE_RELEASE },
    });
};
