import { Typography } from '@mui/material';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export function OrderLabel({ children }: PWC) {
    return (
        <Typography variant="bodyShort1" sx={{ fontWeight: 'bold' }} component="div">
            {children}
        </Typography>
    );
}
