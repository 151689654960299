import { Stack, Typography } from '@customink/pigment-react';
import { Button } from '@mui/material';
import { useQueryParam } from '~/adapters/react-router/useQueryParam';
import { useAuth } from '~/contexts/Auth/AuthContext';

export function Auth0Error() {
    const errorDescription = useQueryParam('error_description');

    const { loginWithRedirect } = useAuth();

    return (
        <Stack justifyContent="center" spacing={4} height="50vh">
            <Typography variant="h4" sx={{ fontWeight: 'bold', p: 0 }}>
                There was an error with authentication
            </Typography>
            <Typography variant="bodyLong2">{errorDescription}</Typography>
            <Button onClick={loginWithRedirect}>Return To Login</Button>
        </Stack>
    );
}
