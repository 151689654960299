import { ApiCallResponse } from '~/adapters/services/shared/apiCall';

const PAGE_SIZE = 6;

interface Pager {
    totalCount: number;
    totalPages: number;
    hasMorePages: boolean;
}

export function getPager(
    headers: ApiCallResponse['headers'],
    currentPage: number,
    pageSize: number = PAGE_SIZE,
): Pager {
    const xTotalCount = headers['x-total-count'];
    const totalCount = xTotalCount ? parseInt(xTotalCount, 10) : 0;
    const hasMorePages = currentPage * pageSize < totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);
    return {
        totalCount,
        hasMorePages,
        totalPages,
    };
}
