// the values of AnalyticsConstants are assignable keys of the fieldsObject for ga() function
export const AnalyticsConstants = {
    emailSharesAdded: 'metric10',
    userId: 'dimension118',
    cardIndex: 'dimension84',
    buyNowBulkCount: 'dimension123',
    isDesignsOrdered: 'dimension125',
    accountCompoundId: 'dimension7',
    loginState: 'dimension130',
};
