import { Link } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@customink/pigment-react';
import { UpperCaseChip } from '~/components/Chip/UpperCaseChip';
import { trackEvent } from '~/adapters/tracking/tracking';
import { useVerifyUser } from '~/adapters/services/accounts/auth0/hooks';
import { usePage } from '~/adapters/browser/usePage';
import { useQueryParam } from '~/adapters/react-router/useQueryParam';
import { DesignsPath } from '~/config';
import Spinner from '~/components/Spinner';

export function VerifyUser() {
    const sessionToken = useQueryParam('sessionToken') ?? '';
    const { mutate, isPending } = useVerifyUser();

    usePage({
        title: 'Verify User',
        reportPath: '/accounts/verify',
    });

    return (
        <Stack spacing={3}>
            <div>
                <UpperCaseChip label="one last step" />
            </div>
            <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold' }}>
                Verify Your Account
            </Typography>
            <Stack gap={2}>
                <Typography variant="bodyLong2" sx={{ fontWeight: 500 }}>
                    We sent you an email with easy, one-click instructions for activating your account.
                </Typography>
                <Typography variant="bodyLong2" sx={{ fontWeight: 500 }}>
                    Didn’t receive the email? Click below to resend it.
                </Typography>
            </Stack>
            <Button
                variant="primary"
                size="medium"
                onClick={() => {
                    trackEvent({
                        category: 'Account',
                        action: 'Button Click',
                        label: 'Resend verification',
                    });
                    mutate(sessionToken);
                }}
                disabled={isPending}
                fullWidth
            >
                {isPending ? <Spinner /> : 'Resend'}
            </Button>

            <Box justifyContent="center" display="flex">
                <Link to={DesignsPath}>Continue to my Account</Link>
            </Box>
        </Stack>
    );
}
