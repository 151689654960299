import { addDataLayerEvent, CATEGORY } from '~/adapters/tracking/tracking';
import { AnalyticsConstants } from '~/adapters/tracking/AnalyticsConstants';
import {
    Design,
    Item,
    PriceQuoteResponse,
    Product,
    ProductDetails,
    ProductsDetailsResponse,
} from '~/adapters/services/accounts/designs/types';
import { parseProductNameAndColor } from '~/adapters/services/accounts/designs/transformer';

export const gaCategoryNew = 'Account Designs';
export const gaCategoryOld = `${CATEGORY} saved designs category`; // in order to ensure continuity
export const gaCreateDimension = (cardIndex: number) => ({ [AnalyticsConstants.cardIndex]: cardIndex });

export function trackGA4AddToCart(
    design: Design,
    cardIndex: number,
    designQuoteData?: PriceQuoteResponse,
    designProductDetailsData?: ProductsDetailsResponse,
) {
    addDataLayerEvent({ ecommerce: null });
    addDataLayerEvent({
        event: 'add_to_cart',
        ecommerce: {
            currencyCode: 'USD',
            value: designQuoteData?.quote?.price ?? '',
            items: design.products.map((designProduct: Product) => {
                const matchingQuoteProduct = designQuoteData?.quote?.items.find(
                    (item: Item) => item.productId === designProduct.productId,
                );
                const [designProductName, designProductColor] = parseProductNameAndColor(
                    designProduct.productNameAndColor,
                );
                const matchingProductDetail = designProductDetailsData?.products.find(
                    (productDetail: ProductDetails) => productDetail.styleId === designProduct.styleNo,
                );
                return {
                    item_id: designProduct.productId,
                    item_name: designProductName,
                    affiliation: 'external_core',
                    index: cardIndex,
                    item_brand: matchingProductDetail?.itemBrand ?? '',
                    item_category: matchingProductDetail?.itemCategories?.[0] ?? '',
                    item_category2: matchingProductDetail?.itemCategories?.[1] ?? '',
                    item_category3: matchingProductDetail?.itemCategories?.[2] ?? '',
                    item_variant: designProductColor,
                    price: matchingQuoteProduct?.unitPrice ?? '',
                    quantity: matchingQuoteProduct?.quantity ?? '',
                };
            }),
        },
    });
}
