import { PWC } from '~/adapters/typescript/propsWithChildren';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';
import { ProfilesSessionGuard } from '~/contexts/Auth/Profiles/ProfilesSessionGuard';
import { AuthErrorBoundary } from '~/contexts/Auth/AuthErrorBoundary';
import { ProfilesProvider } from './Profiles/ProfilesProvider';
import { Auth0ProviderWrapper } from './Auth0/Auth0ProviderWrapper';

export const AuthProvider = ({ children }: PWC) => {
    const isAuth0Enabled = useSignalmanFeatureFlag('auth0');

    return (
        <AuthErrorBoundary>
            {isAuth0Enabled ? (
                <Auth0ProviderWrapper>{children}</Auth0ProviderWrapper>
            ) : (
                <ProfilesProvider>
                    <ProfilesSessionGuard>{children}</ProfilesSessionGuard>
                </ProfilesProvider>
            )}
        </AuthErrorBoundary>
    );
};
