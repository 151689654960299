import {
    ECMCertificateDto,
    ECMCustomerDto,
    ECMBusinessAddressDto,
    CertExpressInvitationDto,
    transformECMCertificateDto,
} from '~/adapters/services/accounts/taxExemptionsECM/types';
import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useAccountsApiCall } from '~/adapters/services/accounts/accountsApiCall';
import { useMemo } from 'react';
import { AxiosError } from 'axios';

export type CreateCustomerParams = Pick<ECMCustomerDto, 'name'> & {
    businessAddress: Partial<ECMBusinessAddressDto>;
    emailAddress: NonNullable<ECMCustomerDto['emailAddress']>;
};
export type UpdateCustomerParams = Omit<Partial<CreateCustomerParams>, 'id'>;

class TaxExemptionsRepository {
    constructor(private readonly accountsApiCall: ApiCall) {}

    public getCertificates = async (accountId: number) => {
        const response = await this.accountsApiCall<Array<ECMCertificateDto>>(
            `/accounts/${accountId}/tax-exemptions/certificates`,
        );
        return response.data.map(transformECMCertificateDto);
    };

    public getCustomer = async (accountId: number) => {
        try {
            const response = await this.accountsApiCall<ECMCustomerDto>(
                `/accounts/${accountId}/tax-exemptions/customer`,
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                return null;
            }
            throw error;
        }
    };

    public createCustomer = async (accountId: number, customer: CreateCustomerParams) => {
        const response = await this.accountsApiCall<ECMCustomerDto>(`/accounts/${accountId}/tax-exemptions/customer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: customer,
        });

        return response.data;
    };

    public updateCustomer = async (customerId: number, customer: UpdateCustomerParams) => {
        const response = await this.accountsApiCall<ECMCustomerDto>(`/tax-exemptions/customer/${customerId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: customer,
        });

        return response.data;
    };

    public createOrGetTaxExemptionPortalLink = async (accountId: number) => {
        const response = await this.accountsApiCall<CertExpressInvitationDto>(
            `/accounts/${accountId}/tax-exemptions/cert-express-invites`,
            {
                method: 'PUT',
            },
        );

        return response.data;
    };
}

export const useTaxExemptionsRepository = () => {
    const accountsApiCall = useAccountsApiCall();
    return useMemo(() => new TaxExemptionsRepository(accountsApiCall), []);
};
