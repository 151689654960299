import { Typography } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export function FeedbackLabel({ children }: PWC) {
    return (
        <Typography variant="bodyShort1" sx={(theme) => ({ color: theme.palette.grey[400] })}>
            {children}
        </Typography>
    );
}
