import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Box, IconButton, RowsIcon, Stack, useMobileBreakpoint } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Typography } from '@mui/material';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { ResponsiveShrinkingInputBox } from '../ResponsiveInputBox/ResponsiveInputBox';
import { FilterId, PageControlsPlaceHolderProps } from './PageControls';

const PageHeaderLayout = styled(Stack)({
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
});

export const PageHeaderToggleLayout: FC<PWC<PageControlsPlaceHolderProps>> = ({ children, pageControlsElementId }) => (
    <PageHeaderLayout>
        <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold', pb: 2, whiteSpace: 'nowrap' }}>
            {children}
        </Typography>
        <Box id={pageControlsElementId || FilterId} />
    </PageHeaderLayout>
);

const ToggleIcon = styled(RowsIcon, {
    shouldForwardProp: (prop: string) => prop !== 'showMobile2columns',
})<{ showMobile2columns: boolean }>(({ showMobile2columns, theme }) => ({
    transform: showMobile2columns ? 'rotate(90deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
}));

type PageSortToggleLayoutProps = PWC<{
    showMobile2columns: boolean;
    setShowMobile2columns: Dispatch<SetStateAction<boolean>>;
}>;

export const PageSortToggleLayout: FC<PageSortToggleLayoutProps> = ({
    children,
    showMobile2columns,
    setShowMobile2columns,
}) => {
    const isMobile = useMobileBreakpoint();
    const handleColumnsSwitchClick = useCallback(() => setShowMobile2columns((prev) => !prev), []);
    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            {isMobile ? (
                <IconButton onClick={handleColumnsSwitchClick}>
                    <ToggleIcon showMobile2columns={showMobile2columns} />
                </IconButton>
            ) : (
                <Typography variant="bodyLong1" sx={{ whiteSpace: 'nowrap' }}>
                    Sort by:
                </Typography>
            )}
            <ResponsiveShrinkingInputBox>{children}</ResponsiveShrinkingInputBox>
        </Stack>
    );
};
