import { trackEvent } from '~/adapters/tracking/tracking';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';

export function trackFeatureFlag(
    flagKey: string,
    variationKey: string,
    launchDate?: string,
    hitCallback?: EmptyFunction,
) {
    const prefix = launchDate ? `${launchDate} ` : '';
    trackEvent({
        category: 'testing',
        action: `${prefix}nautilus ${flagKey}`,
        label: `${prefix}nautilus ${flagKey} ${variationKey}`,
        hitCallback,
    });
}
