import { Component } from 'react';
import { Typography } from '@mui/material';
import { Container, Stack } from '@customink/pigment-react';
import { ButtonLink } from '~/components/Link/Link';
import { DesignsPath } from '~/config';
import * as Sentry from '@sentry/react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

interface FeedbackErrorBoundaryState {
    hasError: boolean;
}

export class FeedbackErrorBoundary extends Component<PWC, FeedbackErrorBoundaryState> {
    constructor(props: PWC) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container maxWidth="md">
                    {/* The height is an empiric value, a compromise for a good-enough look both on small and large screens. */}
                    <Stack justifyContent="center" alignItems="center" spacing={4} height="50vh">
                        <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold' }}>
                            Access Denied
                        </Typography>
                        <Typography variant="bodyLong2" textAlign="center">
                            We either can&apos;t find that design or you need additional permissions to view it. Check
                            with the person who shared it with you and try again.
                        </Typography>
                        <ButtonLink to={DesignsPath}>Return To Your Account</ButtonLink>
                    </Stack>
                </Container>
            );
        }

        return <>{this.props.children}</>;
    }
}
