import { useCallback, useState } from 'react';
import { Box, Button, ScreenReadersOnly, Stack } from '@customink/pigment-react';
import { useAuth, useAuthUser } from '~/contexts/Auth/AuthContext';
import { LabelValueRow, LabelValueTable } from '~/pages/Settings/components/LabelValueTable';
import { ChangeNameDialog } from '~/pages/Settings/tabs/Profile/ChangeNameDialog';
import { sendNameModalEvent } from '~/pages/Settings/tabs/Profile/profileTracking';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { PasswordChange } from '~/pages/Settings/tabs/Profile/PasswordChange';

export function UserProfile() {
    const { email } = useAuthUser();

    const [dialogName, setDialogName] = useState(false);

    const { identityProvider } = useAuth();
    const isProfilesIdentityProvider = identityProvider === 'profiles';

    const { user: userData } = useUserAccount();

    const openEditName = useCallback(() => {
        sendNameModalEvent('Open');
        setDialogName(true);
    }, []);
    const closeEditName = useCallback(() => {
        sendNameModalEvent('Close');
        setDialogName(false);
    }, []);

    if (!userData) {
        return <Box mb={3}>No User Profile has been found for this Account.</Box>;
    }

    return (
        <>
            <Stack>
                <ScreenReadersOnly>
                    <h2>Profile</h2>
                </ScreenReadersOnly>
                <LabelValueTable>
                    <LabelValueRow
                        label="Name"
                        value={`${userData.firstName} ${userData.lastName}`}
                        action={
                            <Button onClick={openEditName} variant="secondary">
                                {userData.firstName && userData.lastName ? 'Change Name' : 'Set your name'}
                            </Button>
                        }
                    />
                    <LabelValueRow label="Email" value={email} />
                    {isProfilesIdentityProvider ? <PasswordChange /> : null}
                </LabelValueTable>
            </Stack>
            <ChangeNameDialog
                open={dialogName}
                close={closeEditName}
                currentFirstName={userData.firstName}
                currentLastName={userData.lastName}
                userId={userData.id}
            />
        </>
    );
}
