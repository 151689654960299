import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { absurdWithLog } from '~/adapters/typescript/absurdWithLog';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { useLinks } from '~/contexts/LinkContext';
import { FeedbackPath } from '~/config';
import { copyToClipboard } from '~/adapters/browser/clipboard/copyToClipboard';
import {
    CreateResourceErrorCode,
    ReferenceType,
    SharingRole,
    SharingUser,
} from '~/adapters/services/collaboration/graphqlTypes';
import {
    DesignReviewInfoKey,
    useCloneResourceForUserMutation,
    useCreateResourceMutation,
    useGetDesignReviewInfoQuery,
    useUpdateEmailSharesMutation,
    useUpdateLinkShareMutation,
} from '~/adapters/services/collaboration/collaborationHooks';
import {
    pushApiErrorNotification,
    pushErrorNotification,
    pushSuccessNotification,
} from '~/adapters/notistack/notistack';
import { cleanEmailShares } from '~/adapters/services/collaboration/transformers';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';
import * as Sentry from '@sentry/react';

interface SharingAPIProviderProps {
    cid: string;
}

interface ContextInterface {
    createMutationPending: boolean;
    emailShares: SharingUser[];
    linkShareRole?: SharingRole | null; // null means no role (no permissions), undefined means that link sharing is not initialized yet
    isLoading: boolean;
    setEmailSharing: (emailShares: SharingUser[], emailMessage?: string) => void;
    changeLinkSharing: (newRole: SharingRole | null) => void;
    copyLink: (linkRole: SharingRole | null) => Promise<boolean>;
    cloneDesign: (emails: string[], emailMessage?: string) => void;
    resourceId?: string;
}

const SharingAPIContext = createContext<ContextInterface | null>(null);

export function useSharingAPI() {
    const sharingAPIContext = useContext(SharingAPIContext);
    if (!sharingAPIContext) {
        throw new Error('SharingAPI has to be called inside DesignCard');
    }
    return sharingAPIContext;
}

export function SharingAPIProvider({ cid, children }: PWC<SharingAPIProviderProps>) {
    const collaboration = useSignalmanFeatureFlag('collaboration');

    // when createResource is called, we need to block any further createResource calls until we have the resource
    const [createMutationPending, setCreateMutationPending] = useState(false);

    const links = useLinks();

    const client = useQueryClient();
    const queryVars = useMemo(
        () => ({ input: { referenceId: cid, referenceType: ReferenceType.DesignReview } }),
        [cid],
    );
    const { data, isLoading } = useGetDesignReviewInfoQuery(queryVars, { enabled: collaboration });
    const queryKey = useMemo(() => [DesignReviewInfoKey, cid], [cid]);

    const resourceId = data?.resource?.id;

    const linkShareRole: ContextInterface['linkShareRole'] = useMemo(() => data?.resource?.linkShare?.role, [data]);

    const emailShares: SharingUser[] = useMemo(() => {
        if (!data?.resource) {
            return [];
        }
        return cleanEmailShares(data.resource.emailSharing);
    }, [data]);

    // always invalidate the query DesignReviewInfo onSettled of any Mutation
    const invalidateDesignReviewInfoQuery = useCallback(
        () => client.invalidateQueries({ queryKey, exact: true }),
        [client, queryKey],
    );

    const { mutateAsync: createResourceAsync } = useCreateResourceMutation({
        onMutate: () => setCreateMutationPending(true),
        onSettled: async () => {
            await invalidateDesignReviewInfoQuery();
            setCreateMutationPending(false);
        },
    });

    // useUpdateEmailSharesMutation contains its own logic to handle optimistic updates, and cache invalidation is part of that
    const { mutate: updateEmailShares } = useUpdateEmailSharesMutation(queryKey);

    const { mutate: updateLinkShare, mutateAsync: updateLinkShareAsync } = useUpdateLinkShareMutation({
        onSettled: invalidateDesignReviewInfoQuery,
    });

    const handleCreateResourceError = useCallback(
        (errorCode: CreateResourceErrorCode) => {
            switch (errorCode) {
                // this really should not happen, since the user is interacting with an existing design
                case CreateResourceErrorCode.DesignNotFound:
                    Sentry.captureMessage(`Error in CreateResource: design ${cid} not found`, {
                        level: 'error',
                    });
                    pushApiErrorNotification('Something went wrong.');
                    break;
                // I don't believe this can happen either
                case CreateResourceErrorCode.NoProductsForDesign:
                    pushErrorNotification('We are sorry, but it is not possible to share a design with no products.');
                    break;
                default:
                    absurdWithLog(errorCode);
            }
        },
        [cid],
    );

    // the referenced types are desired, because ContextProvider would allow to assign a value that doesn't match ContextInterface
    const setEmailSharing: ContextInterface['setEmailSharing'] = useCallback(
        async (updatedEmailShares, emailMessage) => {
            if (data?.resource) {
                updateEmailShares({
                    input: {
                        resourceId: data.resource.id,
                        users: updatedEmailShares,
                        message: emailMessage || undefined,
                    },
                });
                return;
            }
            const result = await createResourceAsync({
                input: {
                    referenceId: cid,
                    referenceType: ReferenceType.DesignReview,
                    emailShares: updatedEmailShares,
                    message: emailMessage,
                },
            });
            if (result.createResource.errors.length) {
                handleCreateResourceError(result.createResource.errors[0].code);
                return;
            }
            pushSuccessNotification('Your design has been shared.');
        },
        [data, cid],
    );

    const { mutate: cloneDesignMutate } = useCloneResourceForUserMutation();

    const cloneDesign: ContextInterface['cloneDesign'] = useCallback(
        (emails: string[], message?: string) => {
            cloneDesignMutate({
                input: {
                    emails,
                    message,
                    referenceId: cid,
                    referenceType: ReferenceType.DesignReview,
                },
            });
            // error handling for share as copy is not yet handled, so the success notification is fired right away
            pushSuccessNotification('Your design has been shared.');
        },
        [cid],
    );

    const changeLinkSharing: ContextInterface['changeLinkSharing'] = useCallback(
        (newRole) => {
            // on SelectChange, only update permission if link already exists (creating a resource is meaningful only when user can copy the link)
            if (data?.resource?.linkShare) {
                updateLinkShare({
                    input: {
                        resourceId: data.resource.id,
                        role: newRole,
                    },
                });
            }
        },
        [data],
    );

    const createShareableLink = useCallback(
        (linkShareResourceId: string, hash: string): string => {
            const basePathTrimmed = links.basePath.replace(/\/+$/, '');
            const fullFeedbackPath = generatePath(FeedbackPath, { resourceId: linkShareResourceId });
            return `${basePathTrimmed}/profiles${fullFeedbackPath}?code=${hash}`;
        },
        [links],
    );

    const copyLink: ContextInterface['copyLink'] = useCallback(
        async (linkRole) => {
            // safety fallback – copyLink should not be called with unspecified permission (e.g. button should be hidden)
            if (!linkRole) {
                return false;
            }

            const linkSharingOnError = (error: unknown) => {
                Sentry.captureException(`Error in updateLinkShareAsync of design ${cid}: ${error}`, {
                    level: 'error',
                });
                pushApiErrorNotification('Something went wrong.');
            };

            if (data?.resource?.linkShare?.hash) {
                copyToClipboard(createShareableLink(data.resource.id, data.resource.linkShare.hash));
                return true;
            }

            if (data?.resource) {
                try {
                    const result = await updateLinkShareAsync({
                        input: {
                            resourceId: data.resource.id,
                            role: linkRole,
                        },
                    });
                    copyToClipboard(createShareableLink(data.resource.id, result.updateLinkShare.hash));
                    return true;
                } catch (error) {
                    linkSharingOnError(error);
                    return false;
                }
            }

            try {
                const result = await createResourceAsync({
                    input: {
                        referenceId: cid,
                        referenceType: ReferenceType.DesignReview,
                        linkShare: linkRole,
                    },
                });
                const createdResource = result.createResource?.resource;
                if (!createdResource) {
                    linkSharingOnError(new Error(`Resource for ${cid} failed to create`));
                    return false;
                }
                copyToClipboard(createShareableLink(createdResource.id, createdResource.linkShare?.hash || ''));
                return true;
            } catch (error) {
                linkSharingOnError(error);
                return false;
            }
        },
        [data, cid, createShareableLink],
    );

    const providerContext = useMemo(
        () => ({
            createMutationPending,
            emailShares,
            linkShareRole,
            isLoading,
            setEmailSharing,
            changeLinkSharing,
            copyLink,
            cloneDesign,
            resourceId,
        }),
        [
            createMutationPending,
            emailShares,
            linkShareRole,
            isLoading,
            setEmailSharing,
            changeLinkSharing,
            copyLink,
            cloneDesign,
            resourceId,
        ],
    );
    return <SharingAPIContext.Provider value={providerContext}>{children}</SharingAPIContext.Provider>;
}
