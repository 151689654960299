import { CardActions, CardContent, CardHeader, styled, Theme } from '@mui/material';
import * as React from 'react';
import { Box, Button, Card, CardMedia, Carousel } from '@customink/pigment-react';
import { CATEGORY, trackEvent } from '~/adapters/tracking/tracking';
import { AnalyticsConstants } from '~/adapters/tracking/AnalyticsConstants';
import { GroupOrder, GroupOrderWithOrigin } from '~/adapters/services/accounts/groupOrders/types';
import { CarouselProductImage } from '~/components/Image/CarouselProductImage';
import { productCarouselImages } from '~/adapters/services/mms/links';
import { useGroupOrderLinks } from '~/adapters/services/accounts/groupOrders/links';
import { parseProductNameAndColor } from '~/adapters/services/accounts/designs/transformer';
import { formatDate } from '~/adapters/date/date';

function sendGroupOrderEvent({ action, cardIndex }: { action: string; cardIndex: number }): void {
    const category = `${CATEGORY} group order category`;
    trackEvent({ category, action, dimension: { [AnalyticsConstants.cardIndex]: cardIndex } });
}

type ArtifactStatus = 'completed' | 'active' | 'inactive' | 'needsAttention';

const statusToColor = (theme: Theme, status: ArtifactStatus) => {
    const translator: Record<ArtifactStatus, string> = {
        completed: theme.palette.success.main,
        active: '#5ccfc2',
        inactive: theme.palette.grey[400],
        needsAttention: theme.palette.error.main,
    };

    return translator[status];
};

const ArtifactCard = styled('span')<{ status: ArtifactStatus }>(({ theme, status }) => ({
    paddingRight: '12px',
    paddingLeft: '12px',
    display: 'inline-block',
    color: 'white',
    height: '26px',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: '26px',
    position: 'absolute',
    zIndex: 1,
    top: '27px',
    backgroundColor: statusToColor(theme, status),
}));

const GroupOrderProgress = styled(Box)({
    '&::after': {
        clear: 'both',
        content: '""',
        display: 'table',
    },
});

interface Props {
    groupOrder: GroupOrderWithOrigin;
}

// eslint-disable-next-line complexity
const StateDate: React.FC<{ groupOrder: GroupOrder }> = ({ groupOrder }) => {
    switch (groupOrder.state) {
        case 'open':
        case 'closed':
            return groupOrder.crowdsourced ? (
                <div>Closes On: {formatDate(groupOrder.endsOn)}</div>
            ) : (
                <div>Started On: {formatDate(groupOrder.createdAt)}</div>
            );
        case 'canceled':
            return groupOrder.canceledAt ? <div>Canceled On: {formatDate(groupOrder.canceledAt)} </div> : null;
        case 'canceling':
            return (
                <div>
                    {groupOrder.canceledAt ? `Canceled On: ${formatDate(groupOrder.canceledAt)}` : 'Canceling...'}
                </div>
            );
        case 'placed':
            return groupOrder.placedAt ? <div>Placed On: {formatDate(groupOrder.placedAt)}</div> : null;
        default:
            // Hard fallback for weird error cases can happen if someone messes something up in order service
            return <div />;
    }
};

const Details: React.FC<{ groupOrder: GroupOrder }> = ({ groupOrder }) => {
    if (groupOrder.crowdsourced) {
        return (
            <GroupOrderProgress>
                <span>Ordered: {groupOrder.totalQuantity}</span>
                <span> Goal: {groupOrder.expectedQuantity}</span>
            </GroupOrderProgress>
        );
    }
    return <GroupOrderProgress>Recipients: {groupOrder.totalPurchases}</GroupOrderProgress>;
};

// eslint-disable-next-line complexity
const StateLabel: React.FC<{ state: string; isCrowdsourced: boolean }> = ({ state, isCrowdsourced }) => {
    switch (state) {
        case 'closed':
            if (isCrowdsourced) {
                return <ArtifactCard status="needsAttention">CLOSED</ArtifactCard>;
            }
            return <ArtifactCard status="active">IN PROGRESS</ArtifactCard>;
        case 'placed':
            return <ArtifactCard status="completed">PLACED</ArtifactCard>;
        case 'canceled':
            return <ArtifactCard status="inactive">CANCELED</ArtifactCard>;
        case 'canceling':
            return <ArtifactCard status="inactive">CANCELING</ArtifactCard>;
        default:
            return <ArtifactCard status="needsAttention">{isCrowdsourced ? 'OPEN' : 'IN PROGRESS'}</ArtifactCard>;
    }
};

export function GroupOrderCard({ groupOrder }: Props) {
    const carouselImages = productCarouselImages(
        groupOrder.products.map((product) => ({
            ...product,
            productId: product.productId.toString(),
            compositeId: groupOrder.compositeId,
        })),
    );
    const slides = carouselImages.map((uri) => <CarouselProductImage alt="Product preview" src={uri} key={uri} />);

    const links = useGroupOrderLinks(groupOrder);

    const productName = groupOrder.products[0]
        ? parseProductNameAndColor(groupOrder.products[0].productNameAndColor)[0]
        : null;

    return (
        <Card
            sx={{
                position: 'relative',
                pb: 0.5,
            }}
        >
            <CardMedia>
                <Carousel
                    slides={slides}
                    sliderHeight="auto"
                    loop={false}
                    onPreviousClick={() => sendGroupOrderEvent({ action: 'view back', cardIndex: groupOrder.id })}
                    onNextClick={() => sendGroupOrderEvent({ action: 'view next', cardIndex: groupOrder.id })}
                />
                <StateLabel state={groupOrder.state} isCrowdsourced={groupOrder.crowdsourced} />
            </CardMedia>
            <CardHeader title={groupOrder.name} />
            <CardContent>
                {productName && (
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        Product: {productName}
                    </div>
                )}
                <Details groupOrder={groupOrder} />
                <StateDate groupOrder={groupOrder} />
            </CardContent>
            <CardActions>
                {links.map((groupOrderLink, index) => (
                    <Button
                        key={groupOrderLink.label}
                        variant={index === 0 ? 'secondary' : 'link'}
                        sx={{ mb: index === 0 ? 2 : '' }}
                        href={groupOrderLink.url}
                        fullWidth
                    >
                        {groupOrderLink.label}
                    </Button>
                ))}
            </CardActions>
        </Card>
    );
}
