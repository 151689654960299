import { useCallback } from 'react';
import { Button, Stack } from '@customink/pigment-react';
import { signaler, SignalerFeatureFlagKey } from '~/adapters/signaler/signaler';
import { useSignalerContext } from '~/contexts/SignalerContext';

interface VariationsProps {
    variations: string[];
    featureKey: SignalerFeatureFlagKey;
}

export function Variations({ variations, featureKey }: VariationsProps) {
    const currentVariation = signaler.featureFlag(featureKey);
    const { onFeatureFlagUpdate } = useSignalerContext();

    const setFeatureFlag = useCallback(
        (variation: string) => {
            signaler.setFeatureFlag(featureKey, variation);
            onFeatureFlagUpdate();
        },
        [featureKey],
    );

    return (
        <Stack spacing={2} direction="row">
            {variations.map((variant) => (
                <Button
                    variant={currentVariation === variant ? 'primary' : 'secondary'}
                    key={variant}
                    onClick={() => setFeatureFlag(variant)}
                >
                    {variant}
                </Button>
            ))}
        </Stack>
    );
}
