import React, { ReactNode, useMemo } from 'react';
import { useLinks } from '~/contexts/LinkContext';
import { useInfiniteGroupOrders } from '~/adapters/services/accounts/groupOrders/hooks';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import { usePage } from '~/adapters/browser/usePage';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { GroupOrderList } from '~/pages/GroupOrders/GroupOrderList';
import { useInfiniteStoresGroupOrders } from '~/adapters/services/accounts/stores/hooks';
import { GroupOrderWithOrigin } from '~/adapters/services/accounts/groupOrders/types';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { CardListLoader } from '~/components/Layout/CardListLoader';

export interface Props {
    children?: ReactNode;
}

const GroupOrders: React.FC<Props> = () => {
    const links = useLinks();
    const { id: accountId } = useAccount();

    usePage({ title: 'Group Orders', reportPath: '/accounts/gof' });

    const {
        data: storeGroupOrders,
        isFetching: isStoreGroupOrdersFetching,
        isLoading: isStoreGroupOrdersLoading,
        fetchNextPage: fetchNextStoreGroupOrders,
        hasNextPage: hasMorePagesStoresGroupOrders,
    } = useInfiniteStoresGroupOrders({ accountId });

    // both of the infiniteQueries are fetching subsequently, first StoresGroupOrders, then GroupOrders
    // it is a quite an unusual situation to have to serialize them at FE – so we have to determine which one is to be fetched next
    const isNextPageStores = isStoreGroupOrdersFetching || hasMorePagesStoresGroupOrders;

    const {
        data: groupOrders,
        isFetching: isGroupOrdersFetching,
        isLoading: isGroupOrdersLoading,
        fetchNextPage: fetchNextGroupOrders,
        hasNextPage: hasMorePagesGroupOrders,
    } = useInfiniteGroupOrders({ accountId }, { enabled: !isNextPageStores });

    const storeGroupOrderItems = useFlattenPaginatedData(storeGroupOrders, 'groupOrders');
    const groupOrderItems = useFlattenPaginatedData(groupOrders, 'groupOrders');
    const mergedGroupOrderItems = useMemo<GroupOrderWithOrigin[]>(() => {
        return [...storeGroupOrderItems, ...groupOrderItems];
    }, [storeGroupOrderItems, groupOrderItems]);

    const onLoadMore = useMemo(
        () => (isNextPageStores ? fetchNextStoreGroupOrders : fetchNextGroupOrders),
        [isNextPageStores, fetchNextStoreGroupOrders, fetchNextGroupOrders],
    );

    const isLoading = (isGroupOrdersLoading || isStoreGroupOrdersLoading) && !mergedGroupOrderItems.length;

    return (
        <>
            <PageHeader>Group Orders</PageHeader>
            <ErrorBoundary errorMessage="An error occurred while retrieving your group orders">
                {isLoading ? (
                    <CardListLoader />
                ) : (
                    <GroupOrderList
                        cards={mergedGroupOrderItems}
                        groupsLink={links.groupsLink}
                        loading={isStoreGroupOrdersFetching || isGroupOrdersFetching}
                        onLoadMore={onLoadMore}
                        hasMorePages={hasMorePagesStoresGroupOrders || hasMorePagesGroupOrders}
                    />
                )}
            </ErrorBoundary>
        </>
    );
};

export default GroupOrders;
